import Layout from "../components/Layout";
import PricingFeatures from "./PricingFeatures";
import Customers from "../components/Customers";
// PricingTable is PricingFeautres
const PricingPage = () => {
  return (
    <Layout>
      <PricingFeatures />
      <Customers />
    </Layout>
  );
};

export default PricingPage;
