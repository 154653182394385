import { jwtDecode, JwtPayload } from "jwt-decode";

export interface DecodedTokenData extends JwtPayload {
  token: string;
  account_id: string;
  name: string;
  website: string[];
}

export function verifyMe(cb?: (error: Error) => void) {
  const accessToken = localStorage.getItem("token");
  if (!accessToken) {
    localStorage.removeItem("token");
    const error = new Error("Access Token is missing");
    if (cb) return cb(error);
    else throw error;
  }
  const decodedToken = jwtDecode<DecodedTokenData>(accessToken);
  if (
    typeof decodedToken.exp === "number" &&
    decodedToken.exp < Date.now() / 1000
  ) {
    localStorage.removeItem("token");
    const error = new Error("Token expired");
    if (cb) return cb(error);
    else throw error;
  }
  return decodedToken;
}
