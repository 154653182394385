import { type ReactNode } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog.tsx";
import { Calendar } from "@/components/ui/calendar.tsx";
import { format, formatDistanceStrict } from "date-fns";
import { de } from "date-fns/locale";
import { DateRange } from "react-day-picker";
import { Button } from "@/components/ui/button.tsx";

interface DateFilterDialogProps {
  trigger: ReactNode;
  dateRange?: DateRange;
  setDateRange?: (dateRange?: DateRange) => void;
  onSubmit?: () => void;
}

function DateFilterDialog({
  onSubmit,
  trigger,
  dateRange,
  setDateRange,
}: DateFilterDialogProps) {
  return (
    <Dialog>
      <DialogTrigger asChild className="w-full">
        {trigger}
      </DialogTrigger>
      <DialogContent
        className="max-w-screen-sm"
        onSubmit={(e) => console.log(e)}
      >
        <DialogHeader>
          <DialogTitle>Datumsbereich auswählen</DialogTitle>
          <DialogDescription>
            {dateRange?.from ? (
              dateRange.to ? (
                <>
                  {formatDistanceStrict(dateRange.from, dateRange.to, {
                    addSuffix: true,
                    locale: de,
                  })}{" "}
                  ({format(dateRange.from, "d. MMM yyyy", { locale: de })} -{" "}
                  {format(dateRange.to, "d. MMM yyyy", { locale: de })})
                </>
              ) : (
                format(dateRange.from, "d. MMM yyyy", { locale: de })
              )
            ) : (
              "Ausgewählt..."
            )}
          </DialogDescription>
        </DialogHeader>
        <div className="w-auto p-0">
          <Calendar
            classNames={{
              months:
                "flex flex-col justify-center items-center sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
            }}
            initialFocus
            mode="range"
            defaultMonth={dateRange?.from}
            selected={dateRange}
            onSelect={setDateRange}
            numberOfMonths={2}
            locale={de}
          />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button disabled={!dateRange?.from} onClick={onSubmit}>
              Anwenden
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default DateFilterDialog;
