import React, { useState, useEffect, useRef } from "react";
import RegisterModal from "../modal/RegisterModal";
import analytics from "../../analytics";
import demo_screen_light from "../../assets/images/demo_screen_light.png";

const HeroHeader = () => {
  const [website, setWebsite] = useState("");
  const [animatedWebsite, setAnimatedWebsite] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const animationTimeoutRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [personalizationData, setPersonalizationData] = useState(null);
  const [animatedGreeting, setAnimatedGreeting] = useState("");
  const [animatedCompanyName, setAnimatedCompanyName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [greetingText, setGreetingText] = useState("");
  const [animatedH1Text, setAnimatedH1Text] = useState("");
  const [showCursor, setShowCursor] = useState(true);
  const greetingRef = useRef("");
  const cursorIntervalRef = useRef(null);
  const dataFetchedRef = useRef(false);
  const [startTyping, setStartTyping] = useState(false);

  const sampleWebsites = [
    "meinefirma.de",
    "onlineshop24.de",
    "messe.de",
    "gesundheit-plus.de",
    "tech-innovationen.de",
    "energie.de",
  ];

  const handleAppointmentClick = () => {
    analytics.track("appointment_button_clicked", {
      source: "hero_header",
    });
    window.open(
      "https://outlook.office365.com/book/DMEXCOTerminvereinbaren@webmetic.de/",
      "_blank",
      "noopener,noreferrer",
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      if (dataFetchedRef.current) return; // Prevent multiple fetches
      dataFetchedRef.current = true;

      const urlParams = new URLSearchParams(window.location.search);
      const isDebug = urlParams.get("debug") === "true";

      try {
        const endpoint = `https://hub.webmetic.de/wcd${isDebug ? "?debug=true" : ""}`;
        const response = await Promise.race([
          fetch(endpoint),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Timeout")), 400),
          ),
        ]);
        const data = await response.json();
        if (data) {
          setPersonalizationData(data);

          // Check if hero_text is "generating" or "send_generating"
          if (
            data.hero_text === "generating" ||
            data.hero_text === "send_generating"
          ) {
            const fetchHeroText = async () => {
              try {
                const heroTextResponse = await fetch(
                  `https://hub.webmetic.de/wcd/hero_text/${data.company_id}`,
                );
                const heroTextData = await heroTextResponse.json();
                if (
                  heroTextData.hero_text !== "generating" &&
                  heroTextData.hero_text !== "send_generating"
                ) {
                  setPersonalizationData((prevData) => ({
                    ...prevData,
                    hero_text: heroTextData.hero_text,
                  }));
                } else {
                  setTimeout(fetchHeroText, 100); // Retry after 100ms
                }
              } catch (error) {
                console.error("Error fetching hero text:", error);
              }
            };

            fetchHeroText();
          }
        }
      } catch (error) {
        console.error("Error fetching personalization data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const animateWebsite = (
    index = 0,
    direction = "forward",
    currentText = "",
  ) => {
    if (isInputFocused || website) return;

    if (index === sampleWebsites.length) {
      index = 0;
    }

    const targetWebsite = sampleWebsites[index];

    if (direction === "forward") {
      if (currentText.length < targetWebsite.length) {
        setAnimatedWebsite(targetWebsite.slice(0, currentText.length + 1));
        animationTimeoutRef.current = setTimeout(
          () =>
            animateWebsite(
              index,
              "forward",
              currentText + targetWebsite[currentText.length],
            ),
          100,
        );
      } else {
        animationTimeoutRef.current = setTimeout(
          () => animateWebsite(index, "backward", currentText),
          1000,
        );
      }
    } else {
      if (currentText.length > 0) {
        setAnimatedWebsite(currentText.slice(0, -1));
        animationTimeoutRef.current = setTimeout(
          () => animateWebsite(index, "backward", currentText.slice(0, -1)),
          50,
        );
      } else {
        setAnimatedWebsite("");
        animationTimeoutRef.current = setTimeout(
          () => animateWebsite(index + 1, "forward", ""),
          1000,
        );
      }
    }
  };

  useEffect(() => {
    if (!isInputFocused && !website) {
      animateWebsite();
    } else {
      setAnimatedWebsite("");
    }
    return () => {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, [isInputFocused, website]);

  const handleInputChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
    if (personalizationData && personalizationData.company_url) {
      setWebsite(personalizationData.company_url); // Fill input with company URL when focused
    }
    setAnimatedWebsite(""); // Clear animated website when focused
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    analytics.track("register_modal_opened", {
      source: "hero_header_button",
      website: website,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const animatePersonalizedText = (text, setText, delay = 50) => {
    let index = 0;
    setTimeout(() => {
      const animate = () => {
        if (index < text.length) {
          setText((prev) => prev + text[index]);
          index++;
          setTimeout(animate, delay);
        }
      };
      animate();
    }, 100); // 200ms delay before starting the animation
  };

  useEffect(() => {
    if (!isLoading && personalizationData) {
      greetingRef.current = `${personalizationData.greeting} ${
        personalizationData.company_name_cleaned
          ? personalizationData.company_name_cleaned +
            "!\nTreffen wir uns auf der DMEXCO?"
          : ""
      }`.trim();
    } else if (!isLoading) {
      greetingRef.current = "\nTreffen wir uns auf der DMEXCO?".trim();
    }

    // Start typing after 200ms
    const timer = setTimeout(() => setStartTyping(true), 600);
    return () => clearTimeout(timer);
  }, [isLoading, personalizationData]);

  useEffect(() => {
    if (
      startTyping &&
      greetingRef.current &&
      animatedGreeting !== greetingRef.current
    ) {
      const timeout = setTimeout(() => {
        setAnimatedGreeting(
          greetingRef.current.slice(0, animatedGreeting.length + 1),
        );
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [animatedGreeting, startTyping]);

  // Blinking cursor effect
  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 500);

    const stopBlinkingTimeout = setTimeout(() => {
      clearInterval(cursorInterval);
      setShowCursor(false); // Ensure cursor is hidden after stopping the blink
    }, 5000);

    return () => {
      clearInterval(cursorInterval);
      clearTimeout(stopBlinkingTimeout);
    };
  }, []);

  const getHeroText = () => {
    if (personalizationData) {
      return personalizationData.hero_text;
    }
    return "Wir unterstützen Sie dabei, Ihre Besucher zu erkennen, personalisierte Ansprache zu entwickeln und effektives Retargeting umzusetzen.";
  };
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-4 pb-2 pt-8 lg:pt-16">
        <div className="mb-2 grid items-center gap-8 lg:mb-4 lg:grid-cols-12 lg:gap-12">
          <div className="col-span-6 text-center sm:mb-6 lg:mb-0 lg:text-left">
            <h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-white md:text-3xl xl:text-4xl">
              {animatedGreeting.split("\n").map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  {index < animatedGreeting.split("\n").length - 1 && <br />}
                </React.Fragment>
              ))}
              {showCursor && <span className="animate-blink">|</span>}
            </h1>
            <p className="mx-auto mb-6 max-w-xl font-light text-gray-500 dark:text-gray-400 md:text-lg lg:mx-0 xl:mb-8 xl:text-xl">
              {isLoading ||
              personalizationData?.hero_text === "generating" ||
              personalizationData?.hero_text === "send_generating" ? (
                <div role="status" className="max-w-sm animate-pulse">
                  <div className="mb-2.5 h-2 max-w-[330px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
                  <div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
                  <div className="mb-2.5 h-2 max-w-[330px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
                  <div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
                  <div className="h-2 max-w-[360px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                getHeroText()
              )}
            </p>
            <button
              onClick={handleAppointmentClick}
              className="btn bg-primary-70 text-white"
            >
              Jetzt Termin vereinbaren
            </button>
          </div>
          <div className="col-span-6">
            <div className="relative mx-auto h-[172px] max-w-[301px] rounded-t-xl border-[8px] border-gray-800 bg-gray-800 dark:border-gray-800 md:h-[294px] md:max-w-[512px]">
              <div className="h-[156px] overflow-hidden rounded-lg bg-white dark:bg-gray-800 md:h-[278px]">
                <img
                  src={demo_screen_light}
                  className="h-[156px] w-full rounded-lg dark:hidden md:h-[278px]"
                  alt=""
                />
                <img
                  src={demo_screen_light}
                  className="hidden h-[156px] w-full rounded-lg dark:block md:h-[278px]"
                  alt=""
                />
              </div>
            </div>
            <div className="relative mx-auto h-[17px] max-w-[351px] rounded-b-xl rounded-t-sm bg-gray-900 dark:bg-gray-700 md:h-[21px] md:max-w-[597px]">
              <div className="absolute left-1/2 top-0 h-[5px] w-[56px] -translate-x-1/2 rounded-b-xl bg-gray-800 md:h-[8px] md:w-[96px]"></div>
            </div>
          </div>
        </div>
      </div>
      <RegisterModal
        isOpen={isModalOpen}
        onClose={closeModal}
        initialWebsite={website}
      />
    </section>
  );
};

export default HeroHeader;
