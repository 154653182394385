import { verifyMe } from "@/helpers/verify-me.ts";
import { redirect } from "react-router-dom";

function dashboardLoader() {
  console.log("### Checked for JWT ###")
  const user = verifyMe(() => null)!;
  if (!user) return redirect("/login");
  return null;
}

export default dashboardLoader;
