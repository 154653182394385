import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
// If dark mode is needed, import `dark.css`.
import "react18-json-view/src/dark.css";

// If needed, you can use the internal stringify function.
// import { stringify } from 'react18-json-view'
const JsonViewDemo = () => {
  const companyData = {
    company_id: "55947421",
    company_name: "HORNBACH Baumarkt AG",
    company_url: "https://hornbach.de",
    company_logo_url:
      "https://webmetic.de/brand/hornbach_de/hornbach_de_dark_logo.png",
    address: "Hornbachstraße 11",
    postal_code: "76879",
    city: "Hochstadt",
    country: "Deutschland",
    phone_number: "+49694201270",
    email_address: "service-frankfurt@hornbach.com",
    registration_number: "HRB 2311",
    registration_court: "Amtsgericht Landau",
    vat_id: "DE151116749",
    directors: [
      "Erich Harsch",
      "Karin Dohm",
      "Jan Hornbach",
      "Nils Hornbach",
      "Susanne Jäger",
      "Karsten Kühn",
      "Ingo Leiner",
      "Dr. Andreas Schobert",
      "Christa Theurer",
    ],
    short_description_de:
      "Heimwerker-Markt mit umfassendem Angebot an Produkten und Dienstleistungen für DIY, Garten und Renovierungsprojekte.",
    short_description_en:
      "Home improvement retailer offering a wide range of products and services for DIY, gardening, and renovation projects.",
    full_description_de:
      "Umfassender Heimwerker-Markt mit großem Angebot an Produkten und Dienstleistungen für DIY-Begeisterte, Gärtner und Hausbesitzer. Das Sortiment umfasst Kategorien wie Baustoffe, Sanitärwaren, Bodenbeläge, Türen und Fenster, Elektro- und Beleuchtung, Küchen- und Badeinrichtungen, Gartengeräte und -zubehör, Smart-Home-Systeme und vieles mehr. Darüber hinaus bietet der Markt verschiedene Dienstleistungen wie Projektplanung, Installation und Wartungsunterstützung an.",
    full_description_en:
      "Comprehensive home improvement retailer providing a vast array of products and services for DIY enthusiasts, gardeners, and homeowners. Offers a wide range of categories including building materials, sanitaryware, flooring, doors and windows, electrical and lighting, kitchen and bathroom fittings, gardening tools and equipment, smart home systems, and more. Additionally, provides various services such as project planning, installation, and maintenance support.",
    facebook: "https://facebook.com/hornbach.de",
    instagram: "https://instagram.com/hornbach_de",
    linkedin: "https://linkedin.com/company/hornbach-baumarkt-ag",
    youtube: "https://youtube.com/user/Hornbach",
    lead_score: 92.93,
    nace_codes: [
      {
        code: "43.31",
        full_code: "F 43.31",
        section_code: "F",
        section_description_de: "Baugewerbe/bau",
        section_description_en: "Construction",
        division_code: "F43",
        division_description_de:
          "Vorbereitende Baustellenarbeiten, Bauinstallation und sonstiges Ausbaugewerbe",
        division_description_en: "Specialised construction activities",
        description_de: "Anbringen von Stuckaturen, Gipserei und Verputzerei",
        description_en: "Plastering",
      },
      {
        code: "43.32",
        full_code: "F 43.32",
        section_code: "F",
        section_description_de: "Baugewerbe/bau",
        section_description_en: "Construction",
        division_code: "F43",
        division_description_de:
          "Vorbereitende Baustellenarbeiten, Bauinstallation und sonstiges Ausbaugewerbe",
        division_description_en: "Specialised construction activities",
        description_de: "Bautischlerei und -schlosserei",
        description_en: "Joinery installation",
      },
      {
        code: "43.33",
        full_code: "F 43.33",
        section_code: "F",
        section_description_de: "Baugewerbe/bau",
        section_description_en: "Construction",
        division_code: "F43",
        division_description_de:
          "Vorbereitende Baustellenarbeiten, Bauinstallation und sonstiges Ausbaugewerbe",
        division_description_en: "Specialised construction activities",
        description_de: "Fußboden-, Fliesen- und Plattenlegerei, Tapeziererei",
        description_en: "Floor and wall covering",
      },
      {
        code: "46.73",
        full_code: "G 46.73",
        section_code: "G",
        section_description_de:
          "Handel; Instandhaltung und Reparatur von Kraftfahrzeugen",
        section_description_en:
          "Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles",
        division_code: "G46",
        division_description_de:
          "Großhandel (ohne Handel mit Kraftfahrzeugen und Krafträdern)",
        division_description_en:
          "Wholesale trade, except of motor vehicles and motorcycles",
        description_de:
          "Großhandel mit Holz, Baustoffen, Anstrichmitteln und Sanitärkeramik",
        description_en:
          "Wholesale of wood, construction materials and sanitary equipment",
      },
      {
        code: "47.52",
        full_code: "G 47.52",
        section_code: "G",
        section_description_de:
          "Handel; Instandhaltung und Reparatur von Kraftfahrzeugen",
        section_description_en:
          "Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles",
        division_code: "G47",
        division_description_de:
          "Einzelhandel (ohne Handel mit Kraftfahrzeugen)",
        division_description_en:
          "Retail trade, except of motor vehicles and motorcycles",
        description_de:
          "Einzelhandel mit Metallwaren, Anstrichmitteln, Bau- und Heimwerkerbedarf",
        description_en:
          "Retail sale of hardware, paints and glass in specialised stores",
      },
    ],
    sessions: [
      {
        client_id:
          "c5bf72fc28ba897a2e7ef66fe3d86201d2fd0a94f299416d6894a29d361482ac",
        session_id:
          "173f5aaa85f090fc110e82c63da12c616747da2cc8e5988e7381e4034ecea8e0",
        aid: "m3j4y5",
        weburl: "https://webmetic.de/funktionen",
        timestamp: "2024-07-24T15:36:11.654Z",
        user_agent:
          "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36",
        platform: "Windows",
        screen_resolution: "1920x1080",
        color_depth: "24-bit",
        viewport: "1680x925",
        cookies_enabled: true,
        connection_type: "wifi",
        color_scheme: "light",
        user_language: "de-DE",
        utm_source: "google",
        utm_medium: "cpc",
        utm_campaign: "webmetic_features",
        session_duration: 120.45,
      },
      {
        client_id:
          "c5bf72fc28ba897a2e7ef66fe3d86201d2fd0a94f299416d6894a29d361482ac",
        session_id:
          "295f7bbb96g101ed221f93d74da13d727858eb3dd9f6099f8492f5145fdfb9f1",
        aid: "n4k5z6",
        weburl: "https://webmetic.de/preise",
        timestamp: "2024-07-31T17:15:30.123Z",
        user_agent:
          "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36",
        platform: "Windows",
        screen_resolution: "1920x1080",
        color_depth: "24-bit",
        viewport: "1680x925",
        cookies_enabled: true,
        connection_type: "wifi",
        color_scheme: "dark",
        user_language: "de-DE",
        utm_source: "google",
        utm_medium: "cpc",
        utm_campaign: "webmetic",
        utm_content: "retargeting",
        session_duration: 180.3,
      },
      {
        client_id:
          "b8dg93ef40cb908b3f8fg77gf4e97312e3ge1b05g300517e7905b30e472593bd",
        session_id:
          "306g8ccc07h212fe332g04e85eb24e838969fc4ee0g7100g9503g6256gegc0g2",
        aid: "o5l6a7",
        weburl: "https://webmetic.de/kontakt",
        timestamp: "2024-08-05T15:43:55.132Z",
        user_agent:
          "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36",
        platform: "Windows",
        screen_resolution: "1920x1080",
        color_depth: "24-bit",
        viewport: "1680x925",
        cookies_enabled: true,
        connection_type: "wifi",
        color_scheme: "light",
        user_language: "de-DE",
        utm_source: "google",
        utm_medium: "organic",
        session_duration: 90.15,
      },
    ],
  };
  return (
    <section className="border-b border-t border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-4 py-8 text-gray-500 dark:text-gray-400 lg:py-16">
        <h2 className="mb-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white md:text-4xl">
          Demo-Datensatz
        </h2>
        <p className="mb-8 md:text-xl">
          Entdecken Sie in diesem JSON die wertvollen Informationen, die Sie für
          jedes Unternehmen erhalten. <br />
          Anpassbar und geeignet für nahezu jeden individuellen Anwendungsfall.
        </p>
        <div className="bg-gray-50 p-10 dark:bg-gray-700">
          <JsonView
            src={companyData}
            theme="winter-is-coming"
            enableClipboard={false}
            dark={false}
            matchesURL={true}
            collapsed={1}
            displaySize={1}
            displayArrayIndex={false}
            collapseStringMode="directly"
            collapseStringsAfterLength={80}
            className="text-sm"
          />
        </div>
      </div>
    </section>
  );
};
export default JsonViewDemo;
