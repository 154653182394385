import { QueryClient, queryOptions } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router-dom";

export interface CompaniesFilterDate {
  companies: number;
  date_end: string;
  date_start: string;
  label: string;
}

export async function getCompaniesFilter(domain: string) {
  const res = await fetch(
    `${import.meta.env.VITE_APP_API}/api/companies_counts?domain=${domain}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  );
  return (await res.json()) as CompaniesFilterDate[];
}

export function companiesFilterQueryKey(domain: string) {
  return ["companies", "filter", domain];
}

export function companiesFilterQueryOptions(domain: string) {
  return queryOptions({
    queryKey: companiesFilterQueryKey(domain),
    queryFn: () => getCompaniesFilter(domain),
  });
}

function companiesFilterLoader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) => {
    await queryClient.ensureQueryData(
      companiesFilterQueryOptions(params.domain!),
    );
    return null;
  };
}

export default companiesFilterLoader;
