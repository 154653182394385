export enum CompanyMode {
  company = "company",
  path = "path",
  utm = "utm",
}

export type Company = {
  company_name: string;
  company_url: string;
  company_logo_url: string;
  lead_score: number;
  city: string;
  country: string;
  document_locations: string[];
  utm_parameters: string[];
  industry?: string;
  company_id: string;
  last_session_timestamp?: string;
};
