import { SearchInput } from "@/components";
import { CompanyMode } from "@/types";

type CompaniesListSearchProps = {
  searchValue: string;
  onChangeSearch: (val: string) => void;
  dropDownValue?: string;
  onChangeDropdownValue: (val: string) => void;
};

export const CompaniesListSearch = (props: CompaniesListSearchProps) => {
  //TODO mova to constants
  const options = [
    {
      value: CompanyMode.company,
      label: "Unternehmen",
    },
    {
      value: CompanyMode.path,
      label: "Pfad",
    },
    {
      value: CompanyMode.utm,
      label: "UTM",
    },
  ];
  return (
    <div className="relative w-full">
      <SearchInput categoryList={options} {...props} />
    </div>
  );
};
