import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const Datenschutzerklaerung = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <section className="bg-white py-8 dark:bg-gray-900 lg:py-24">
          <div className="format dark:format-invert mx-auto max-w-4xl px-4 lg:px-4">
            <h1 className="h2-style mb-2">Datenschutzerklärung</h1>
            <p className="mb-6 text-lg text-gray-600 dark:text-gray-400 lg:text-lg">
              Zuletzt aktualisiert: 09.10.2024
            </p>
            <p className="dark:text-white">
              Sie erhalten als Nutzer unserer Internetseite in dieser
              Datenschutzerklärung alle notwendigen Informationen darüber, wie,
              in welchem Umfang sowie zu welchem Zweck wir oder Drittanbieter
              Daten von Ihnen erheben und diese verwenden. Die Erhebung und
              Nutzung Ihrer Daten erfolgt streng nach den Vorgaben des
              Bundesdatenschutzgesetzes (BDSG) und des Telemediengesetzes (TMG).
              Wir fühlen uns der Vertraulichkeit Ihrer personenbezogenen Daten
              besonders verpflichtet und arbeiten deshalb streng innerhalb der
              Grenzen, die die gesetzlichen Vorgaben uns setzen. Die Erhebung
              dieser personenbezogenen Daten erfolgt auf freiwilliger Basis,
              wenn uns das möglich ist. Auch geben wir diese Daten nur mit Ihrer
              ausdrücklichen Zustimmung an Dritte weiter. Wir sorgen bei
              besonders vertraulichen Daten wie im Zahlungsverkehr oder im
              Hinblick auf Ihre Anfragen an uns durch Einsatz einer
              SSL-Verschlüsselung für hohe Sicherheit. Wir möchten es aber an
              dieser Stelle nicht versäumen, auf die allgemeinen Gefahren der
              Internetnutzung hinzuweisen, auf die wir keinen Einfluss haben.
              Besonders im E-Mail-Verkehr sind Ihre Daten ohne weitere
              Vorkehrungen nicht sicher und können unter Umständen von Dritten
              erfasst werden.
            </p>
            <p className="h5-style mb-2 mt-4">1. Datenschutz auf einen Blick</p>
            <p className="h6-style mb-2 mt-4">Allgemeine Hinweise</p>
            <p className="dark:text-white">
              Die folgenden Hinweise geben einen einfachen &Uuml;berblick
              dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn
              Sie diese Website besuchen. Personenbezogene Daten sind alle
              Daten, mit denen Sie pers&ouml;nlich identifiziert werden
              k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz
              entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten
              Datenschutzerkl&auml;rung.
            </p>
            <p className="h6-style mb-2 mt-4">
              Datenerfassung auf dieser Website
            </p>
            <p className="mb-2 mt-4 font-bold dark:text-white">
              Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser
              Website?
            </p>
            <p className="dark:text-white">
              Die Datenverarbeitung auf dieser Website erfolgt durch den
              Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem
              Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in
              dieser Datenschutzerkl&auml;rung entnehmen.
            </p>
            <p className="mb-2 mt-4 font-bold dark:text-white">
              Wie erfassen wir Ihre Daten?
            </p>
            <p className="dark:text-white">
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
              mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die
              Sie in ein Kontaktformular eingeben.
            </p>
            <p className="dark:text-white">
              Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
              Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
              allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem
              oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten
              erfolgt automatisch, sobald Sie diese Website betreten.
            </p>
            <p className="mb-2 mt-4 font-bold dark:text-white">
              Wof&uuml;r nutzen wir Ihre Daten?
            </p>
            <p className="dark:text-white">
              Ein Teil der Daten wird erhoben, um eine fehlerfreie
              Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten
              k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </p>
            <p className="mb-2 mt-4 font-bold dark:text-white">
              Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?
            </p>
            <p className="dark:text-white">
              Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
              Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
              personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
              Recht, die Berichtigung oder L&ouml;schung dieser Daten zu
              verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung
              erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit
              f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das
              Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
              Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen
              Aufsichtsbeh&ouml;rde zu.
            </p>
            <p className="dark:text-white">
              Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen
              Sie sich jederzeit an uns wenden.
            </p>
            <p className="h6-style mb-2 mt-4">
              Analyse-Tools und Tools von Dritt&shy;anbietern
            </p>
            <p className="dark:text-white">
              Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
              ausgewertet werden. Das geschieht vor allem mit sogenannten
              Analyseprogrammen.
            </p>
            <p className="dark:text-white">
              Detaillierte Informationen zu diesen Analyseprogrammen finden Sie
              in der folgenden Datenschutzerkl&auml;rung.
            </p>
            <p className="h5-style mb-2 mt-4">
              2. Rollen und Verantwortlichkeiten
            </p>

            <p className="dark:text-white">
              Webmetic nimmt bei der Verarbeitung Ihrer Daten verschiedene
              Rollen ein:
            </p>

            <p className="mb-2 mt-4 font-bold dark:text-white">
              Als Verantwortlicher:
            </p>
            <p className="dark:text-white">
              Wir sind datenschutzrechtlich verantwortlich, wenn wir:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                Besucher-Tracking auf unserer eigenen Website durchführen
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                eigene Marketing- und Analysezwecke verfolgen
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Newsletter versenden
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Kundenanfragen bearbeiten
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                die Registrierung und Verwaltung von Kundenkonten durchführen
              </li>
            </ul>

            <p className="mb-2 mt-4 font-bold dark:text-white">
              Als Auftragsverarbeiter:
            </p>
            <p className="dark:text-white">
              Wir verarbeiten Daten im Auftrag unserer Kunden, wenn diese:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                unsere Tracking- und Analysewerkzeuge auf ihren Websites
                einsetzen
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                unsere Plattform zur Besucheranalyse nutzen
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                unsere Tools zur Verwaltung ihrer Kundendaten einsetzen
              </li>
            </ul>

            <p className="dark:text-white">
              Die Verarbeitung als Auftragsverarbeiter erfolgt ausschließlich:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                auf dokumentierte Weisung des jeweiligen Kunden
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                gemäß den geschlossenen Auftragsverarbeitungsvereinbarungen
                (AVV)
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                unter Einhaltung technischer und organisatorischer Maßnahmen
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                unter Wahrung der Betroffenenrechte
              </li>
            </ul>

            <p className="h5-style mb-2 mt-4">
              3. Datenerhebung und -verarbeitung
            </p>

            <p className="dark:text-white">
              Wir legen besonderen Wert darauf, dass Ihre Daten ausschließlich
              in Deutschland verarbeitet werden:
            </p>

            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                Alle unsere Server und Systeme befinden sich in deutschen
                Rechenzentren
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Wir arbeiten ausschließlich mit Dienstleistern, die eine
                Datenverarbeitung in Deutschland garantieren
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Es erfolgt keine Übermittlung personenbezogener Daten in andere
                EU-Länder oder Drittstaaten
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Auch unsere Auftragsverarbeiter sind vertraglich verpflichtet,
                Daten ausschließlich in Deutschland zu verarbeiten
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Backups und Archivierung erfolgen ebenfalls nur auf Servern in
                Deutschland
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Bei der Auswahl von Dienstleistern und Tools ist die
                Datenverarbeitung in Deutschland ein zentrales Auswahlkriterium
              </li>
            </ul>

            <p className="dark:text-white">
              Diese strikte Beschränkung auf deutsche Standorte gewährleistet
              höchste Datenschutzstandards und vermeidet rechtliche
              Unsicherheiten bei internationalen Datentransfers.
            </p>

            <p className="h5-style mb-2 mt-4">
              4. Erstellung und Analyse von Nutzerprofilen
            </p>

            <p className="dark:text-white">
              Im Rahmen der Nutzung unserer Dienste erstellen und analysieren
              wir Nutzerprofile. Dies geschieht unter strikter Beachtung der
              datenschutzrechtlichen Vorgaben:
            </p>

            <p className="mb-2 mt-4 font-bold dark:text-white">
              Umfang der Profilerstellung:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                Analyse des Nutzungsverhaltens auf Websites unserer Kunden
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Erfassung von Interaktionen mit Website-Elementen
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Auswertung von Conversion-Ereignissen
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Zusammenführung von Nutzungsdaten zu pseudonymisierten Profilen
              </li>
            </ul>

            <p className="mb-2 mt-4 font-bold dark:text-white">
              Zweck der Profilerstellung:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                Verbesserung der Benutzerfreundlichkeit von Websites
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Optimierung von Marketing-Maßnahmen
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Analyse von Nutzerverhalten zur Verbesserung des Angebots
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Erstellung von aggregierten Statistiken
              </li>
            </ul>

            <p className="mb-2 mt-4 font-bold dark:text-white">
              Schutzmaßnahmen:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                Strikte Pseudonymisierung aller erhobenen Daten
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Keine Verarbeitung besonderer Kategorien personenbezogener Daten
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Keine Erstellung von Profilen für rein private Nutzung
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Löschung der Profile nach Zweckerfüllung
              </li>
            </ul>

            <p className="h5-style mb-2 mt-4">
              5. Datenverarbeitung während Testzeiträumen
            </p>

            <p className="dark:text-white">
              Während der kostenlosen Testphase unserer Dienste verarbeiten wir
              Ihre Daten wie folgt:
            </p>

            <p className="mb-2 mt-4 font-bold dark:text-white">
              Erhobene Daten während der Testphase:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                Kontaktdaten (Name, E-Mail-Adresse, Telefonnummer)
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Unternehmensdaten (Firmenname, Adresse, Branche)
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Nutzungsdaten der Testversion
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Technische Zugriffsdaten
              </li>
            </ul>

            <p className="mb-2 mt-4 font-bold dark:text-white">
              Verwendungszwecke:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                Bereitstellung der Testversion
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Support und Beratung während der Testphase
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Analyse der Nutzung zu Optimierungszwecken
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Kontaktaufnahme bei Fragen oder Problemen
              </li>
            </ul>

            <p className="mb-2 mt-4 font-bold dark:text-white">
              Aufbewahrung und Löschung:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                Speicherung während des Testzeitraums (14 Tage)
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Bei Vertragsabschluss: Überführung in reguläre Kundendaten
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Ohne Vertragsabschluss: Löschung 30 Tage nach Testende
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Ausnahme: Daten, die aufgrund gesetzlicher Aufbewahrungsfristen
                länger gespeichert werden müssen
              </li>
            </ul>

            <p className="dark:text-white">
              Rechtsgrundlage für die Datenverarbeitung während der Testphase
              ist Art. 6 Abs. 1 lit. b DSGVO (Durchführung vorvertraglicher
              Maßnahmen). Sie können der Verarbeitung Ihrer Daten jederzeit
              widersprechen. In diesem Fall wird Ihr Testzugang deaktiviert und
              Ihre Daten werden gemäß den oben genannten Fristen gelöscht.
            </p>

            <p className="h5-style mb-2 mt-4">
              6. Auftragsverarbeitung und Verträge
            </p>

            <p className="dark:text-white">
              Als Anbieter von Analyse- und Tracking-Lösungen verarbeiten wir
              Daten im Auftrag unserer Kunden. Diese Verarbeitung ist wie folgt
              geregelt:
            </p>

            <p className="mb-2 mt-4 font-bold dark:text-white">
              Auftragsverarbeitungsvereinbarung (AVV):
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                Mit jedem Kunden wird eine schriftliche AVV gemäß Art. 28 DSGVO
                geschlossen
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Die AVV regelt Rechte und Pflichten beider Parteien im Detail
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Festlegung von technischen und organisatorischen Maßnahmen
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Regelungen zur Weisungsgebundenheit der Datenverarbeitung
              </li>
            </ul>

            <p className="mb-2 mt-4 font-bold dark:text-white">
              Pflichten als Auftragsverarbeiter:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                Verarbeitung nur nach dokumentierter Weisung des
                Verantwortlichen
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Gewährleistung der Vertraulichkeit der Datenverarbeitung
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Umsetzung geeigneter technischer und organisatorischer Maßnahmen
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Unterstützung bei der Erfüllung der Betroffenenrechte
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Löschung oder Rückgabe der Daten nach Auftragsende
              </li>
            </ul>

            <p className="h5-style mb-2 mt-4">
              7. Technische und Organisatorische Maßnahmen
            </p>

            <p className="dark:text-white">
              Zum Schutz Ihrer Daten haben wir umfangreiche technische und
              organisatorische Maßnahmen implementiert:
            </p>

            <p className="mb-2 mt-4 font-bold dark:text-white">
              Technische Maßnahmen:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                SSL/TLS-Verschlüsselung für alle Datenübertragungen
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Mehrstufige Firewallsysteme
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Regelmäßige Sicherheitsupdates und Patches
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Verschlüsselte Datenspeicherung
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Regelmäßige Backups
              </li>
            </ul>

            <p className="mb-2 mt-4 font-bold dark:text-white">
              Organisatorische Maßnahmen:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                Regelmäßige Schulungen der Mitarbeiter
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Dokumentierte Zugriffsberechtigungskonzepte
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Verpflichtung der Mitarbeiter auf Vertraulichkeit
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Regelmäßige Datenschutz-Audits
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Incident-Response-Prozesse
              </li>
            </ul>

            <p className="dark:text-white">
              Diese Maßnahmen werden regelmäßig überprüft und an aktuelle
              technische Entwicklungen und rechtliche Anforderungen angepasst.
              Eine detaillierte Beschreibung unserer technischen und
              organisatorischen Maßnahmen stellen wir unseren Kunden im Rahmen
              der Auftragsverarbeitungsvereinbarung zur Verfügung.
            </p>

            <p className="h5-style mb-2 mt-4">8. Hosting</p>
            <p className="dark:text-white">
              Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
            </p>
            <p className="h6-style mb-2 mt-4">Hetzner</p>
            <p className="dark:text-white">
              Anbieter ist die Hetzner Online GmbH, Industriestr. 25, 91710
              Gunzenhausen (nachfolgend Hetzner).
            </p>
            <p className="dark:text-white">
              Details entnehmen Sie der Datenschutzerkl&auml;rung von Hetzner:
              <a
                href="https://www.hetzner.com/de/rechtliches/datenschutz"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.hetzner.com/de/rechtliches/datenschutz
              </a>
              .
            </p>
            <p className="dark:text-white">
              Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
              m&ouml;glichst zuverl&auml;ssigen Darstellung unserer Website.
              Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt
              die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6
              Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG, soweit die
              Einwilligung die Speicherung von Cookies oder den Zugriff auf
              Informationen im Endger&auml;t des Nutzers (z.&nbsp;B.
              Device-Fingerprinting) im Sinne des TDDDG umfasst. Die
              Einwilligung ist jederzeit widerrufbar.
            </p>
            <p className="mb-2 mt-4 font-bold dark:text-white">
              Auftragsverarbeitung
            </p>
            <p className="dark:text-white">
              Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
              Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt
              es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
              der gew&auml;hrleistet, dass dieser die personenbezogenen Daten
              unserer Websitebesucher nur nach unseren Weisungen und unter
              Einhaltung der DSGVO verarbeitet.
            </p>
            <p className="h5-style mb-2 mt-4">
              9. Allgemeine Hinweise und Pflicht&shy;informationen
            </p>
            <p className="h6-style mb-2 mt-4">Datenschutz</p>
            <p className="dark:text-white">
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer
              pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre
              personenbezogenen Daten vertraulich und entsprechend den
              gesetzlichen Datenschutzvorschriften sowie dieser
              Datenschutzerkl&auml;rung.
            </p>
            <p className="dark:text-white">
              Wenn Sie diese Website benutzen, werden verschiedene
              personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
              mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
              Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche
              Daten wir erheben und wof&uuml;r wir sie nutzen. Sie
              erl&auml;utert auch, wie und zu welchem Zweck das geschieht.
            </p>
            <p className="dark:text-white">
              Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
              (z.&nbsp;B. bei der Kommunikation per E-Mail)
              Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz
              der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
            </p>
            <p className="h6-style mb-2 mt-4">
              Hinweis zur verantwortlichen Stelle
            </p>
            <p className="dark:text-white">
              Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf
              dieser Website ist:
            </p>
            <p className="dark:text-white">
              Intent GmbH
              <br />
              Orionweg 5<br />
              85609 Aschheim
            </p>
            <p className="dark:text-white">
              Telefon: +49 89 3183 5253
              <br />
              E-Mail: mail@webmetic.de
            </p>
            <p className="dark:text-white">
              Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
              Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke
              und Mittel der Verarbeitung von personenbezogenen Daten
              (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
            </p>
            <p className="h6-style mb-2 mt-4">Speicherdauer</p>
            <p className="dark:text-white">
              Soweit innerhalb dieser Datenschutzerkl&auml;rung keine
              speziellere Speicherdauer genannt wurde, verbleiben Ihre
              personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die
              Datenverarbeitung entf&auml;llt. Wenn Sie ein berechtigtes
              L&ouml;schersuchen geltend machen oder eine Einwilligung zur
              Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht,
              sofern wir keine anderen rechtlich zul&auml;ssigen Gr&uuml;nde
              f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben
              (z.&nbsp;B. steuer- oder handelsrechtliche Aufbewahrungsfristen);
              im letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall
              dieser Gr&uuml;nde.
            </p>
            <p className="h6-style mb-2 mt-4">
              Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung
              auf dieser Website
            </p>
            <p className="dark:text-white">
              Sofern Sie in die Datenverarbeitung eingewilligt haben,
              verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von
              Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern
              besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet
              werden. Im Falle einer ausdr&uuml;cklichen Einwilligung in die
              &Uuml;bertragung personenbezogener Daten in Drittstaaten erfolgt
              die Datenverarbeitung au&szlig;erdem auf Grundlage von Art. 49
              Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies
              oder in den Zugriff auf Informationen in Ihr Endger&auml;t
              (z.&nbsp;B. via Device-Fingerprinting) eingewilligt haben, erfolgt
              die Datenverarbeitung zus&auml;tzlich auf Grundlage von &sect; 25
              Abs. 1 TDDDG. Die Einwilligung ist jederzeit widerrufbar. Sind
              Ihre Daten zur Vertragserf&uuml;llung oder zur Durchf&uuml;hrung
              vorvertraglicher Ma&szlig;nahmen erforderlich, verarbeiten wir
              Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
              Weiteren verarbeiten wir Ihre Daten, sofern diese zur
              Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich sind
              auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die
              Datenverarbeitung kann ferner auf Grundlage unseres berechtigten
              Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die
              jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in
              den folgenden Abs&auml;tzen dieser Datenschutzerkl&auml;rung
              informiert.
            </p>
            <p className="h6-style mb-2 mt-4">
              Hinweis zur Datenweitergabe in datenschutzrechtlich nicht sichere
              Drittstaaten sowie die Weitergabe an US-Unternehmen, die nicht
              DPF-zertifiziert sind
            </p>
            <p className="dark:text-white">
              Wir verwenden unter anderem Tools von Unternehmen mit Sitz in
              datenschutzrechtlich nicht sicheren Drittstaaten sowie US-Tools,
              deren Anbieter nicht nach dem EU-US-Data Privacy Framework (DPF)
              zertifiziert sind. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre
              personenbezogene Daten in diese Staaten &uuml;bertragen und dort
              verarbeitet werden. Wir weisen darauf hin, dass in
              datenschutzrechtlich unsicheren Drittstaaten kein mit der EU
              vergleichbares Datenschutzniveau garantiert werden kann.
            </p>
            <p className="dark:text-white">
              Wir weisen darauf hin, dass die USA als sicherer Drittstaat
              grunds&auml;tzlich ein mit der EU vergleichbares Datenschutzniveau
              aufweisen. Eine Daten&uuml;bertragung in die USA ist danach
              zul&auml;ssig, wenn der Empf&auml;nger eine Zertifizierung unter
              dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF) besitzt oder
              &uuml;ber geeignete zus&auml;tzliche Garantien verf&uuml;gt.
              Informationen zu &Uuml;bermittlungen an Drittstaaten
              einschlie&szlig;lich der Datenempf&auml;nger finden Sie in dieser
              Datenschutzerkl&auml;rung.
            </p>
            <p className="h6-style mb-2 mt-4">
              Empfänger von personenbezogenen Daten
            </p>
            <p className="dark:text-white">
              Im Rahmen unserer Gesch&auml;ftst&auml;tigkeit arbeiten wir mit
              verschiedenen externen Stellen zusammen. Dabei ist teilweise auch
              eine &Uuml;bermittlung von personenbezogenen Daten an diese
              externen Stellen erforderlich. Wir geben personenbezogene Daten
              nur dann an externe Stellen weiter, wenn dies im Rahmen einer
              Vertragserf&uuml;llung erforderlich ist, wenn wir gesetzlich
              hierzu verpflichtet sind (z.&nbsp;B. Weitergabe von Daten an
              Steuerbeh&ouml;rden), wenn wir ein berechtigtes Interesse nach
              Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe haben oder wenn eine
              sonstige Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz
              von Auftragsverarbeitern geben wir personenbezogene Daten unserer
              Kunden nur auf Grundlage eines g&uuml;ltigen Vertrags &uuml;ber
              Auftragsverarbeitung weiter. Im Falle einer gemeinsamen
              Verarbeitung wird ein Vertrag &uuml;ber gemeinsame Verarbeitung
              geschlossen.
            </p>
            <p className="h6-style mb-2 mt-4">
              Widerruf Ihrer Einwilligung zur Datenverarbeitung
            </p>
            <p className="dark:text-white">
              Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
              ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen
              eine bereits erteilte Einwilligung jederzeit widerrufen. Die
              Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
              Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
            </p>
            <p className="h6-style mb-2 mt-4">
              Widerspruchsrecht gegen die Datenerhebung in besonderen
              F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)
            </p>
            <p className="dark:text-white">
              Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e
              oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen,
              die sich aus Ihrer besonderen Situation ergeben, gegen die
              Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen;
              dies gilt auch für ein auf diese Bestimmungen gestütztes
              Profiling. Die jeweilige Rechtsgrundlage, auf denen eine
              Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung.
              Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen
              personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir
              können zwingende schutzwürdige Gründe für die Verarbeitung
              nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen
              oder die Verarbeitung dient der Geltendmachung, Ausübung oder
              Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1
              DSGVO).
            </p>
            <p className="dark:text-white">
              Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e
              oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen,
              die sich aus Ihrer besonderen Situation ergeben, gegen die
              Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen;
              dies gilt auch für ein auf diese Bestimmungen gestütztes
              Profiling. Die jeweilige Rechtsgrundlage, auf denen eine
              Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung.
              Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen
              personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir
              können zwingende schutzwürdige Gründe für die Verarbeitung
              nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen
              oder die Verarbeitung dient der Geltendmachung, Ausübung oder
              Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1
              DSGVO). Werden Ihre personenbezogenen Daten verarbeitet, um
              Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit
              Widerspruch gegen die Verarbeitung Sie betreffender
              personenbezogener Daten zum Zwecke derartigen Werbung einzulegen;
              dies gilt auch für das Profiling, soweit es mit solcher
              Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden
              Ihre personenbezogenen Daten anschließend nicht mehr zum Zwecke
              der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2
              DSGVO).
            </p>
            <p className="h6-style mb-2 mt-4">
              Beschwerde&shy;recht bei der zust&auml;ndigen
              Aufsichts&shy;beh&ouml;rde
            </p>
            <p className="dark:text-white">
              Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
              Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
              insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen
              Aufenthalts, ihres Arbeitsplatzes oder des Orts des
              mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht
              unbeschadet anderweitiger verwaltungsrechtlicher oder
              gerichtlicher Rechtsbehelfe.
            </p>
            <p className="h6-style mb-2 mt-4">
              Recht auf Daten&shy;&uuml;bertrag&shy;barkeit
            </p>
            <p className="dark:text-white">
              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert
              verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen,
              maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie
              die direkte &Uuml;bertragung der Daten an einen anderen
              Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
              machbar ist.
            </p>
            <p className="h6-style mb-2 mt-4">
              Auskunft, Berichtigung und L&ouml;schung
            </p>
            <p className="dark:text-white">
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein
              Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu
              sowie zu weiteren Fragen zum Thema personenbezogene Daten
              k&ouml;nnen Sie sich jederzeit an uns wenden.
            </p>
            <p className="h6-style mb-2 mt-4">
              Recht auf Einschr&auml;nkung der Verarbeitung
            </p>
            <p className="dark:text-white">
              Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
              jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der
              Verarbeitung besteht in folgenden F&auml;llen:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der
                Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer
                der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
                Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Wenn die Verarbeitung Ihrer personenbezogenen Daten
                unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt
                der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung
                verlangen.
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen,
                Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder
                Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben
                Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                haben, muss eine Abw&auml;gung zwischen Ihren und unseren
                Interessen vorgenommen werden. Solange noch nicht feststeht,
                wessen Interessen &uuml;berwiegen, haben Sie das Recht, die
                Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
                Daten zu verlangen.
              </li>
            </ul>
            <p className="dark:text-white">
              Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
              eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von
              ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung
              oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
              Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
              nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden
              eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen
              Union oder eines Mitgliedstaats verarbeitet werden.
            </p>
            <p className="h6-style mb-2 mt-4">
              SSL- bzw. TLS-Verschl&uuml;sselung
            </p>
            <p className="dark:text-white">
              Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
              &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel
              Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber
              senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine
              verschl&uuml;sselte Verbindung erkennen Sie daran, dass die
              Adresszeile des Browsers von &bdquo;http://&ldquo; auf
              &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer
              Browserzeile.
            </p>
            <p className="dark:text-white">
              Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist,
              k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von
              Dritten mitgelesen werden.
            </p>
            <p className="h6-style mb-2 mt-4">
              Widerspruch gegen Werbe-E-Mails
            </p>
            <p className="dark:text-white">
              Der Nutzung von im Rahmen der Impressumspflicht
              ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von nicht
              ausdr&uuml;cklich angeforderter Werbung und
              Informationsmaterialien wird hiermit widersprochen. Die Betreiber
              der Seiten behalten sich ausdr&uuml;cklich rechtliche Schritte im
              Falle der unverlangten Zusendung von Werbeinformationen, etwa
              durch Spam-E-Mails, vor.
            </p>
            <p className="h5-style mb-2 mt-4">
              10. Datenerfassung auf dieser Website
            </p>
            <p className="h6-style mb-2 mt-4">Cookies</p>
            <p className="dark:text-white">
              Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;.
              Cookies sind kleine Datenpakete und richten auf Ihrem
              Endger&auml;t keinen Schaden an. Sie werden entweder
              vor&uuml;bergehend f&uuml;r die Dauer einer Sitzung
              (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
              Endger&auml;t gespeichert. Session-Cookies werden nach Ende Ihres
              Besuchs automatisch gel&ouml;scht. Permanente Cookies bleiben auf
              Ihrem Endger&auml;t gespeichert, bis Sie diese selbst l&ouml;schen
              oder eine automatische L&ouml;schung durch Ihren Webbrowser
              erfolgt.
            </p>
            <p className="dark:text-white">
              Cookies k&ouml;nnen von uns (First-Party-Cookies) oder von
              Drittunternehmen stammen (sog. Third-Party-Cookies).
              Third-Party-Cookies erm&ouml;glichen die Einbindung bestimmter
              Dienstleistungen von Drittunternehmen innerhalb von Webseiten
              (z.&nbsp;B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
            </p>
            <p className="dark:text-white">
              Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
              technisch notwendig, da bestimmte Webseitenfunktionen ohne diese
              nicht funktionieren w&uuml;rden (z.&nbsp;B. die Warenkorbfunktion
              oder die Anzeige von Videos). Andere Cookies k&ouml;nnen zur
              Auswertung des Nutzerverhaltens oder zu Werbezwecken verwendet
              werden.
            </p>
            <p className="dark:text-white">
              Cookies, die zur Durchf&uuml;hrung des elektronischen
              Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen
              erw&uuml;nschter Funktionen (z.&nbsp;B. f&uuml;r die
              Warenkorbfunktion) oder zur Optimierung der Website (z.&nbsp;B.
              Cookies zur Messung des Webpublikums) erforderlich sind
              (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit.
              f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben
              wird. Der Websitebetreiber hat ein berechtigtes Interesse an der
              Speicherung von notwendigen Cookies zur technisch fehlerfreien und
              optimierten Bereitstellung seiner Dienste. Sofern eine
              Einwilligung zur Speicherung von Cookies und vergleichbaren
              Wiedererkennungstechnologien abgefragt wurde, erfolgt die
              Verarbeitung ausschlie&szlig;lich auf Grundlage dieser
              Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1
              TDDDG); die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p className="dark:text-white">
              Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber
              das Setzen von Cookies informiert werden und Cookies nur im
              Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte
              F&auml;lle oder generell ausschlie&szlig;en sowie das automatische
              L&ouml;schen der Cookies beim Schlie&szlig;en des Browsers
              aktivieren. Bei der Deaktivierung von Cookies kann die
              Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.
            </p>
            <p className="dark:text-white">
              Welche Cookies und Dienste auf dieser Website eingesetzt werden,
              k&ouml;nnen Sie dieser Datenschutzerkl&auml;rung entnehmen.
            </p>
            <p className="h6-style mb-2 mt-4">Server-Log-Dateien</p>
            <p className="dark:text-white">
              Der Provider der Seiten erhebt und speichert automatisch
              Informationen in so genannten Server-Log-Dateien, die Ihr Browser
              automatisch an uns &uuml;bermittelt. Dies sind:
            </p>
            <ul className="my-2 list-disc space-y-1 pl-6">
              <li className="text-base leading-relaxed dark:text-white">
                Browsertyp und Browserversion
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                verwendetes Betriebssystem
              </li>{" "}
              <li className="text-base leading-relaxed dark:text-white">
                Referrer URL
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Hostname des zugreifenden Rechners
              </li>
              <li className="text-base leading-relaxed dark:text-white">
                Uhrzeit der Serveranfrage
              </li>{" "}
              <li className="text-base leading-relaxed dark:text-white">
                IP-Adresse
              </li>
            </ul>
            <p className="dark:text-white">
              Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen
              wird nicht vorgenommen.
            </p>
            <p className="dark:text-white">
              Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an der technisch fehlerfreien Darstellung und der Optimierung
              seiner Website &ndash; hierzu m&uuml;ssen die Server-Log-Files
              erfasst werden.
            </p>

            <p className="h6-style mb-2 mt-4">
              Datenschutzbestimmungen zu Einsatz und Verwendung von Google
              Analytics (mit Anonymisierungsfunktion)
            </p>
            <p className="dark:text-white">
              Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite die Komponente Google Analytics (mit
              Anonymisierungsfunktion) integriert. Google Analytics ist ein
              Web-Analyse-Dienst. Web-Analyse ist die Erhebung, Sammlung und
              Auswertung von Daten über das Verhalten von Besuchern von
              Internetseiten. Ein Web-Analyse-Dienst erfasst unter anderem Daten
              darüber, von welcher Internetseite eine betroffene Person auf eine
              Internetseite gekommen ist (sogenannte Referrer), auf welche
              Unterseiten der Internetseite zugegriffen oder wie oft und für
              welche Verweildauer eine Unterseite betrachtet wurde.
            </p>
            <p className="dark:text-white">
              Eine Web-Analyse wird überwiegend zur Optimierung einer
              Internetseite und zur Kosten-Nutzen-Analyse von Internetwerbung
              eingesetzt. Betreibergesellschaft der Google-Analytics-Komponente
              ist die Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA
              94043-1351, USA. Der für die Verarbeitung Verantwortliche
              verwendet für die Web-Analyse über Google Analytics den Zusatz
              „_gat._anonymizeIp“. Mittels dieses Zusatzes wird die IP-Adresse
              des Internetanschlusses der betroffenen Person von Google gekürzt
              und anonymisiert, wenn der Zugriff auf unsere Internetseiten aus
              einem Mitgliedstaat der Europäischen Union oder aus einem anderen
              Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum
              erfolgt.
            </p>
            <p className="dark:text-white">
              Der Zweck der Google-Analytics-Komponente ist die Analyse der
              Besucherströme auf unserer Internetseite. Google nutzt die
              gewonnenen Daten und Informationen unter anderem dazu, die Nutzung
              unserer Internetseite auszuwerten, um für uns Online-Reports,
              welche die Aktivitäten auf unseren Internetseiten aufzeigen,
              zusammenzustellen, und um weitere mit der Nutzung unserer
              Internetseite in Verbindung stehende Dienstleistungen zu
              erbringen. Google Analytics setzt ein Cookie auf dem
              informationstechnologischen System der betroffenen Person. Was
              Cookies sind, wurde oben bereits erläutert. Mit Setzung des
              Cookies wird Google eine Analyse der Benutzung unserer
              Internetseite ermöglicht.
            </p>
            <p className="dark:text-white">
              Durch jeden Aufruf einer der Einzelseiten dieser Internetseite,
              die durch den für die Verarbeitung Verantwortlichen betrieben wird
              und auf welcher eine Google-Analytics-Komponente integriert wurde,
              wird der Internetbrowser auf dem informationstechnologischen
              System der betroffenen Person automatisch durch die jeweilige
              Google-Analytics-Komponente veranlasst, Daten zum Zwecke der
              Online-Analyse an Google zu übermitteln. Im Rahmen dieses
              technischen Verfahrens erhält Google Kenntnis über
              personenbezogene Daten, wie der IP-Adresse der betroffenen Person,
              die Google unter anderem dazu dienen, die Herkunft der Besucher
              und Klicks nachzuvollziehen und in der Folge
              Provisionsabrechnungen zu ermöglichen. Mittels des Cookies werden
              personenbezogene Informationen, beispielsweise die Zugriffszeit,
              der Ort, von welchem ein Zugriff ausging und die Häufigkeit der
              Besuche unserer Internetseite durch die betroffene Person,
              gespeichert.
            </p>
            <p className="dark:text-white">
              Bei jedem Besuch unserer Internetseiten werden diese
              personenbezogenen Daten, einschließlich der IP-Adresse des von der
              betroffenen Person genutzten Internetanschlusses, an Google in den
              Vereinigten Staaten von Amerika übertragen. Diese
              personenbezogenen Daten werden durch Google in den Vereinigten
              Staaten von Amerika gespeichert. Google gibt diese über das
              technische Verfahren erhobenen personenbezogenen Daten unter
              Umständen an Dritte weiter.
            </p>
            <p className="dark:text-white">
              Die betroffene Person kann die Setzung von Cookies durch unsere
              Internetseite, wie oben bereits dargestellt, jederzeit mittels
              einer entsprechenden Einstellung des genutzten Internetbrowsers
              verhindern und damit der Setzung von Cookies dauerhaft
              widersprechen. Eine solche Einstellung des genutzten
              Internetbrowsers würde auch verhindern, dass Google ein Cookie auf
              dem informationstechnologischen System der betroffenen Person
              setzt. Zudem kann ein von Google Analytics bereits gesetzter
              Cookie jederzeit über den Internetbrowser oder andere
              Softwareprogramme gelöscht werden.
            </p>
            <p className="dark:text-white">
              Ferner besteht für die betroffene Person die Möglichkeit, einer
              Erfassung der durch Google Analytics erzeugten, auf eine Nutzung
              dieser Internetseite bezogenen Daten sowie der Verarbeitung dieser
              Daten durch Google zu widersprechen und eine solche zu verhindern.
              Hierzu muss die betroffene Person ein Browser-Add-On unter dem
              Link https://tools.google.com/dlpage/gaoptout herunterladen und
              installieren. Dieses Browser-Add-On teilt Google Analytics über
              JavaScript mit, dass keine Daten und Informationen zu den Besuchen
              von Internetseiten an Google Analytics übermittelt werden dürfen.
            </p>
            <p className="dark:text-white">
              Die Installation des Browser-Add-Ons wird von Google als
              Widerspruch gewertet. Wird das informationstechnologische System
              der betroffenen Person zu einem späteren Zeitpunkt gelöscht,
              formatiert oder neu installiert, muss durch die betroffene Person
              eine erneute Installation des Browser-Add-Ons erfolgen, um Google
              Analytics zu deaktivieren. Sofern das Browser-Add-On durch die
              betroffene Person oder einer anderen Person, die ihrem
              Machtbereich zuzurechnen ist, deinstalliert oder deaktiviert wird,
              besteht die Möglichkeit der Neuinstallation oder der erneuten
              Aktivierung des Browser-Add-Ons.
            </p>
            <p className="dark:text-white">
              Weitere Informationen und die geltenden Datenschutzbestimmungen
              von Google können unter
              https://www.google.de/intl/de/policies/privacy/ und unter
              http://www.google.com/analytics/terms/de.html abgerufen werden.
              Google Analytics wird unter
              https://www.google.com/intl/de_de/analytics/ genauer erläutert.
            </p>
            <p className="dark:text-white">
              Datenschutzbestimmungen zu Einsatz und Verwendung von Google
              Remarketing Der für die Verarbeitung Verantwortliche hat auf
              dieser Internetseite Dienste von Google Remarketing integriert.
              Google Remarketing ist eine Funktion von Google-Ads, die es einem
              Unternehmen ermöglicht, bei solchen Internetnutzern Werbung
              einblenden zu lassen, die sich zuvor auf der Internetseite des
              Unternehmens aufgehalten haben.
            </p>
            <p className="dark:text-white">
              Die Integration von Google Remarketing gestattet es einem
              Unternehmen demnach, nutzerbezogene Werbung zu erstellen und dem
              Internetnutzer folglich interessenrelevante Werbeanzeigen anzeigen
              zu lassen. Betreibergesellschaft der Dienste von Google
              Remarketing ist die Google Inc., 1600 Amphitheatre Pkwy, Mountain
              View, CA 94043-1351, USA.
            </p>
            <p className="dark:text-white">
              Zweck von Google Remarketing ist die Einblendung von
              interessenrelevanter Werbung. Google Remarketing ermöglicht es
              uns, Werbeanzeigen über das Google-Werbenetzwerk anzuzeigen oder
              auf anderen Internetseiten anzeigen zu lassen, welche auf die
              individuellen Bedürfnisse und Interessen von Internetnutzern
              abgestimmt sind. Google Remarketing setzt ein Cookie auf dem
              informationstechnologischen System der betroffenen Person. Was
              Cookies sind, wurde oben bereits erläutert. Mit der Setzung des
              Cookies wird Google eine Wiedererkennung des Besuchers unserer
              Internetseite ermöglicht, wenn dieser in der Folge Internetseiten
              aufruft, die ebenfalls Mitglied des Google-Werbenetzwerks sind.
            </p>
            <p className="dark:text-white">
              Mit jedem Aufruf einer Internetseite, auf welcher der Dienst von
              Google Remarketing integriert wurde, identifiziert sich der
              Internetbrowser der betroffenen Person automatisch bei Google. Im
              Rahmen dieses technischen Verfahrens erhält Google Kenntnis über
              personenbezogene Daten, wie der IP-Adresse oder des Surfverhaltens
              des Nutzers, welche Google unter anderem zur Einblendung
              interessenrelevanter Werbung verwendet.
            </p>
            <p className="dark:text-white">
              Mittels des Cookies werden personenbezogene Informationen,
              beispielsweise die durch die betroffene Person besuchten
              Internetseiten, gespeichert. Bei jedem Besuch unserer
              Internetseiten werden demnach personenbezogene Daten,
              einschließlich der IP-Adresse des von der betroffenen Person
              genutzten Internetanschlusses, an Google in den Vereinigten
              Staaten von Amerika übertragen. Diese personenbezogenen Daten
              werden durch Google in den Vereinigten Staaten von Amerika
              gespeichert. Google gibt diese über das technische Verfahren
              erhobenen personenbezogenen Daten unter Umständen an Dritte
              weiter.
            </p>
            <p className="dark:text-white">
              Die betroffene Person kann die Setzung von Cookies durch unsere
              Internetseite, wie oben bereits dargestellt, jederzeit mittels
              einer entsprechenden Einstellung des genutzten Internetbrowsers
              verhindern und damit der Setzung von Cookies dauerhaft
              widersprechen. Eine solche Einstellung des genutzten
              Internetbrowsers würde auch verhindern, dass Google ein Cookie auf
              dem informationstechnologischen System der betroffenen Person
              setzt. Zudem kann ein von Google Analytics bereits gesetzter
              Cookie jederzeit über den Internetbrowser oder andere
              Softwareprogramme gelöscht werden.
            </p>
            <p className="dark:text-white">
              Ferner besteht für die betroffene Person die Möglichkeit, der
              interessenbezogenen Werbung durch Google zu widersprechen. Hierzu
              muss die betroffene Person von jedem der von ihr genutzten
              Internetbrowser aus den Link www.google.de/settings/ads aufrufen
              und dort die gewünschten Einstellungen vornehmen.
            </p>
            <p className="dark:text-white">
              Weitere Informationen und die geltenden Datenschutzbestimmungen
              von Google können unter
              https://www.google.de/intl/de/policies/privacy/ abgerufen werden.
            </p>
            <p className="dark:text-white">
              Datenschutzbestimmungen zu Einsatz und Verwendung von Google-Ads
              Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite Google Ads integriert. Google Ads ist ein Dienst zur
              Internetwerbung, der es Werbetreibenden gestattet, sowohl Anzeigen
              in den Suchmaschinenergebnissen von Google als auch im
              Google-Werbenetzwerk zu schalten. Google Ads ermöglicht es einem
              Werbetreibenden, vorab bestimmte Schlüsselwörter festzulegen,
              mittels derer eine Anzeige in den Suchmaschinenergebnissen von
              Google ausschließlich dann angezeigt wird, wenn der Nutzer mit der
              Suchmaschine ein schlüsselwortrelevantes Suchergebnis abruft.
            </p>
            <p className="dark:text-white">
              Im Google-Werbenetzwerk werden die Anzeigen mittels eines
              automatischen Algorithmus und unter Beachtung der zuvor
              festgelegten Schlüsselwörter auf themenrelevanten Internetseiten
              verteilt. Betreibergesellschaft der Dienste von Google Ads ist die
              Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351,
              USA. Der Zweck von Google Ads ist die Bewerbung unserer
              Internetseite durch die Einblendung von interessenrelevanter
              Werbung auf den Internetseiten von Drittunternehmen und in den
              Suchmaschinenergebnissen der Suchmaschine Google und eine
              Einblendung von Fremdwerbung auf unserer Internetseite.
            </p>
            <p className="dark:text-white">
              Gelangt eine betroffene Person über eine Google-Anzeige auf unsere
              Internetseite, wird auf dem informationstechnologischen System der
              betroffenen Person durch Google ein sogenannter Conversion-Cookie
              abgelegt. Was Cookies sind, wurde oben bereits erläutert. Ein
              Conversion-Cookie verliert nach dreißig Tagen seine Gültigkeit und
              dient nicht zur Identifikation der betroffenen Person. Über den
              Conversion-Cookie wird, sofern das Cookie noch nicht abgelaufen
              ist, nachvollzogen, ob bestimmte Unterseiten, beispielsweise der
              Warenkorb von einem Online-Shop-System, auf unserer Internetseite
              aufgerufen wurden.
            </p>
            <p className="dark:text-white">
              Durch den Conversion-Cookie können sowohl wir als auch Google
              nachvollziehen, ob eine betroffene Person, die über eine
              Ads-Anzeige auf unsere Internetseite gelangt ist, einen Umsatz
              generierte, also einen Warenkauf vollzogen oder abgebrochen hat.
              Die durch die Nutzung des Conversion-Cookies erhobenen Daten und
              Informationen werden von Google verwendet, um Besuchsstatistiken
              für unsere Internetseite zu erstellen. Diese Besuchsstatistiken
              werden durch uns wiederum genutzt, um die Gesamtanzahl der Nutzer
              zu ermitteln, welche über Ads-Anzeigen an uns vermittelt wurden,
              also um den Erfolg oder Misserfolg der jeweiligen Ads-Anzeige zu
              ermitteln und um unsere Ads-Anzeigen für die Zukunft zu
              optimieren. Weder unser Unternehmen noch andere Werbekunden von
              Google-Ads erhalten Informationen von Google, mittels derer die
              betroffene Person identifiziert werden könnte.
            </p>
            <p className="dark:text-white">
              Mittels des Conversion-Cookies werden personenbezogene
              Informationen, beispielsweise die durch die betroffene Person
              besuchten Internetseiten, gespeichert. Bei jedem Besuch unserer
              Internetseiten werden demnach personenbezogene Daten,
              einschließlich der IP-Adresse des von der betroffenen Person
              genutzten Internetanschlusses, an Google in den Vereinigten
              Staaten von Amerika übertragen. Diese personenbezogenen Daten
              werden durch Google in den Vereinigten Staaten von Amerika
              gespeichert. Google gibt diese über das technische Verfahren
              erhobenen personenbezogenen Daten unter Umständen an Dritte
              weiter.
            </p>
            <p className="dark:text-white">
              Die betroffene Person kann die Setzung von Cookies durch unsere
              Internetseite, wie oben bereits dargestellt, jederzeit mittels
              einer entsprechenden Einstellung des genutzten Internetbrowsers
              verhindern und damit der Setzung von Cookies dauerhaft
              widersprechen. Eine solche Einstellung des genutzten
              Internetbrowsers würde auch verhindern, dass Google einen
              Conversion-Cookie auf dem informationstechnologischen System der
              betroffenen Person setzt. Zudem kann ein von Google Ads bereits
              gesetzter Cookie jederzeit über den Internetbrowser oder andere
              Softwareprogramme gelöscht werden.
            </p>
            <p className="dark:text-white">
              Ferner besteht für die betroffene Person die Möglichkeit, der
              interessenbezogenen Werbung durch Google zu widersprechen. Hierzu
              muss die betroffene Person von jedem der von ihr genutzten
              Internetbrowser aus den Link www.google.de/settings/ads aufrufen
              und dort die gewünschten Einstellungen vornehmen.
            </p>
            <p className="dark:text-white">
              Weitere Informationen und die geltenden Datenschutzbestimmungen
              von Google können unter
              https://www.google.de/intl/de/policies/privacy/ abgerufen werden.
            </p>
            <p className="h6-style mb-2 mt-4">Kontaktformular</p>
            <p className="dark:text-white">
              Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
              Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
              angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und
              f&uuml;r den Fall von Anschlussfragen bei uns gespeichert. Diese
              Daten geben wir nicht ohne Ihre Einwilligung weiter.
            </p>
            <p className="dark:text-white">
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung
              eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
              vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
              &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
              berechtigten Interesse an der effektiven Bearbeitung der an uns
              gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
              Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
              wurde; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p className="dark:text-white">
              Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
              uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung
              zur Speicherung widerrufen oder der Zweck f&uuml;r die
              Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
              Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen
              &ndash; insbesondere Aufbewahrungsfristen &ndash; bleiben
              unber&uuml;hrt.
            </p>
            <p className="h6-style mb-2 mt-4">
              Anfrage per E-Mail, Telefon oder Telefax
            </p>
            <p className="dark:text-white">
              Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
              Ihre Anfrage inklusive aller daraus hervorgehenden
              personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
              Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </p>
            <p className="dark:text-white">
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung
              eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
              vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
              &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
              berechtigten Interesse an der effektiven Bearbeitung der an uns
              gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
              Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
              wurde; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p className="dark:text-white">
              Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
              verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r
              die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach
              abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
              gesetzliche Bestimmungen &ndash; insbesondere gesetzliche
              Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
            </p>
            <p className="h5-style mb-2 mt-4">11. Soziale Medien</p>
            <p className="h6-style mb-2 mt-4">Facebook</p>
            <p className="dark:text-white">
              Auf dieser Website sind Elemente des sozialen Netzwerks Facebook
              integriert. Anbieter dieses Dienstes ist die Meta Platforms
              Ireland Limited, Merrion Road, Dublin 4, D04 X2K5, Irland. Die
              erfassten Daten werden nach Aussage von Facebook jedoch auch in
              die USA und in andere Drittl&auml;nder &uuml;bertragen.
            </p>
            <p className="dark:text-white">
              Eine &Uuml;bersicht &uuml;ber die Facebook Social-Media-Elemente
              finden Sie hier:
              <a
                href="https://developers.facebook.com/docs/plugins/?locale=de_DE"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://developers.facebook.com/docs/plugins/?locale=de_DE
              </a>
              .
            </p>
            <p className="dark:text-white">
              Wenn das Social-Media-Element aktiv ist, wird eine direkte
              Verbindung zwischen Ihrem Endger&auml;t und dem Facebook-Server
              hergestellt. Facebook erh&auml;lt dadurch die Information, dass
              Sie mit Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den
              Facebook &bdquo;Like-Button&ldquo; anklicken, w&auml;hrend Sie in
              Ihrem Facebook-Account eingeloggt sind, k&ouml;nnen Sie die
              Inhalte dieser Website auf Ihrem Facebook-Profil verlinken.
              Dadurch kann Facebook den Besuch dieser Website Ihrem
              Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als
              Anbieter der Seiten keine Kenntnis vom Inhalt der
              &uuml;bermittelten Daten sowie deren Nutzung durch Facebook
              erhalten. Weitere Informationen hierzu finden Sie in der
              Datenschutzerkl&auml;rung von Facebook unter:
              <a
                href="https://de-de.facebook.com/privacy/explanation"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://de-de.facebook.com/privacy/explanation
              </a>
              .
            </p>
            <p className="dark:text-white">
              Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer
              Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1
              TDDDG. Die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p className="dark:text-white">
              Soweit mit Hilfe des hier beschriebenen Tools personenbezogene
              Daten auf unserer Website erfasst und an Facebook weitergeleitet
              werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand
              Canal Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam
              f&uuml;r diese Datenverarbeitung verantwortlich (Art. 26 DSGVO).
              Die gemeinsame Verantwortlichkeit beschr&auml;nkt sich dabei
              ausschlie&szlig;lich auf die Erfassung der Daten und deren
              Weitergabe an Facebook. Die nach der Weiterleitung erfolgende
              Verarbeitung durch Facebook ist nicht Teil der gemeinsamen
              Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen
              wurden in einer Vereinbarung &uuml;ber gemeinsame Verarbeitung
              festgehalten. Den Wortlaut der Vereinbarung finden Sie unter:
              <a
                href="https://www.facebook.com/legal/controller_addendum"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/legal/controller_addendum
              </a>
              . Laut dieser Vereinbarung sind wir f&uuml;r die Erteilung der
              Datenschutzinformationen beim Einsatz des Facebook-Tools und
              f&uuml;r die datenschutzrechtlich sichere Implementierung des
              Tools auf unserer Website verantwortlich. F&uuml;r die
              Datensicherheit der Facebook-Produkte ist Facebook verantwortlich.
              Betroffenenrechte (z.&nbsp;B. Auskunftsersuchen) hinsichtlich der
              bei Facebook verarbeiteten Daten k&ouml;nnen Sie direkt bei
              Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns
              geltend machen, sind wir verpflichtet, diese an Facebook
              weiterzuleiten.
            </p>
            <p className="dark:text-white">
              Die Daten&uuml;bertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
              finden Sie hier:
              <a
                href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/legal/EU_data_transfer_addendum
              </a>
              ,
              <a
                href="https://de-de.facebook.com/help/566994660333381"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://de-de.facebook.com/help/566994660333381
              </a>
              und
              <a
                href="https://www.facebook.com/policy.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/policy.php
              </a>
              .
            </p>
            <p className="dark:text-white">
              Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach
              dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist
              ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und
              den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards
              bei Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes
              nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
              Datenschutzstandards einzuhalten. Weitere Informationen hierzu
              erhalten Sie vom Anbieter unter folgendem Link:
              <a
                href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active
              </a>
              .
            </p>
            <p className="h6-style mb-2 mt-4">X (ehemals Twitter)</p>
            <p className="dark:text-white">
              Auf dieser Website sind Funktionen des Dienstes X (ehemals
              Twitter) eingebunden. Diese Funktionen werden angeboten durch den
              Mutterkonzern X Corp., 1355 Market Street, Suite 900, San
              Francisco, CA 94103, USA. F&uuml;r die Datenverarbeitung von
              au&szlig;erhalb der USA lebenden Personen ist die Niederlassung
              Twitter International Unlimited Company, One Cumberland Place,
              Fenian Street, Dublin 2, D02 AX07, Irland, verantwortlich.
            </p>
            <p className="dark:text-white">
              Wenn das Social-Media-Element aktiv ist, wird eine direkte
              Verbindung zwischen Ihrem Endger&auml;t und dem X-Server
              hergestellt. X (ehemals Twitter) erh&auml;lt dadurch Informationen
              &uuml;ber den Besuch dieser Website durch Sie. Durch das Benutzen
              von X (ehemals Twitter) und der Funktion &bdquo;Re-Tweet&ldquo;
              bzw. &bdquo;Repost&ldquo; werden die von Ihnen besuchten Websites
              mit Ihrem X (ehemals Twitter)-Account verkn&uuml;pft und anderen
              Nutzern bekannt gegeben. Wir weisen darauf hin, dass wir als
              Anbieter der Seiten keine Kenntnis vom Inhalt der
              &uuml;bermittelten Daten sowie deren Nutzung durch X (ehemals
              Twitter) erhalten. Weitere Informationen hierzu finden Sie in der
              Datenschutzerkl&auml;rung von X (ehemals Twitter) unter:
              <a
                href="https://x.com/de/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://x.com/de/privacy
              </a>
              .
            </p>
            <p className="dark:text-white">
              Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer
              Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1
              TDDDG. Die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p className="dark:text-white">
              Die Daten&uuml;bertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
              finden Sie hier:
              <a
                href="https://gdpr.x.com/en/controller-to-controller-transfers.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://gdpr.x.com/en/controller-to-controller-transfers.html
              </a>
              .
            </p>
            <p className="dark:text-white">
              Ihre Datenschutzeinstellungen bei X (ehemals Twitter) k&ouml;nnen
              Sie in den Konto-Einstellungen unter
              <a
                href="https://x.com/settings/account"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://x.com/settings/account
              </a>
              &auml;ndern.
            </p>
            <p className="h6-style mb-2 mt-4">LinkedIn</p>
            <p className="dark:text-white">
              Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite Komponenten der LinkedIn Corporation integriert.
              LinkedIn ist ein internetbasiertes soziales Netzwerk, das eine
              Konnektierung der Nutzer mit bestehenden Geschäftskontakten sowie
              das Knüpfen von neuen Businesskontakten ermöglicht. Über 400
              Millionen registrierte Personen nutzen LinkedIn in mehr als 200
              Ländern. Damit ist LinkedIn derzeit die größte Plattform für
              Businesskontakte und eine der meistbesuchten Internetseiten der
              Welt. Betreibergesellschaft von LinkedIn ist die LinkedIn
              Corporation, 2029 Stierlin Court Mountain View, CA 94043, USA.
            </p>
            <p className="dark:text-white">
              Für Datenschutzangelegenheiten außerhalb der USA ist LinkedIn
              Ireland, Privacy Policy Issues, Wilton Plaza, Wilton Place, Dublin
              2, Ireland, zuständig. Bei jedem einzelnen Abruf unserer
              Internetseite, die mit einer LinkedIn-Komponente
              (LinkedIn-Plug-In) ausgestattet ist, veranlasst diese Komponente,
              dass der von der betroffenen Person verwendete Browser eine
              entsprechende Darstellung der Komponente von LinkedIn
              herunterlädt. Weitere Informationen zu den LinkedIn-Plug-Ins
              können unter https://developer.linkedin.com/plugins abgerufen
              werden. Im Rahmen dieses technischen Verfahrens erhält LinkedIn
              Kenntnis darüber, welche konkrete Unterseite unserer Internetseite
              durch die betroffene Person besucht wird.
            </p>
            <p className="dark:text-white">
              Sofern die betroffene Person gleichzeitig bei LinkedIn eingeloggt
              ist, erkennt LinkedIn mit jedem Aufruf unserer Internetseite durch
              die betroffene Person und während der gesamten Dauer des
              jeweiligen Aufenthaltes auf unserer Internetseite, welche konkrete
              Unterseite unserer Internetseite die betroffene Person besucht.
              Diese Informationen werden durch die LinkedIn-Komponente gesammelt
              und durch LinkedIn dem jeweiligen LinkedIn-Account der betroffenen
              Person zugeordnet.
            </p>
            <p className="dark:text-white">
              Betätigt die betroffene Person einen auf unserer Internetseite
              integrierten LinkedIn-Button, ordnet LinkedIn diese Information
              dem persönlichen LinkedIn-Benutzerkonto der betroffenen Person zu
              und speichert diese personenbezogenen Daten. LinkedIn erhält über
              die LinkedIn-Komponente immer dann eine Information darüber, dass
              die betroffene Person unsere Internetseite besucht hat, wenn die
              betroffene Person zum Zeitpunkt des Aufrufes unserer Internetseite
              gleichzeitig bei LinkedIn eingeloggt ist; dies findet unabhängig
              davon statt, ob die betroffene Person die LinkedIn-Komponente
              anklickt oder nicht. Ist eine derartige Übermittlung dieser
              Informationen an LinkedIn von der betroffenen Person nicht
              gewollt, kann diese die Übermittlung dadurch verhindern, dass sie
              sich vor einem Aufruf unserer Internetseite aus ihrem
              LinkedIn-Account ausloggt.
            </p>
            <p className="dark:text-white">
              LinkedIn bietet unter
              https://www.linkedin.com/psettings/guest-controls die Möglichkeit,
              E-Mail-Nachrichten, SMS-Nachrichten und zielgerichtete Anzeigen
              abzubestellen sowie Anzeigen-Einstellungen zu verwalten. LinkedIn
              nutzt ferner Partner wie Quantcast, Google Analytics, BlueKai,
              DoubleClick, Nielsen, Comscore, Eloqua und Lotame, die Cookies
              setzen können. Solche Cookies können unter
              https://www.linkedin.com/legal/cookie-policy abgelehnt werden. Die
              geltenden Datenschutzbestimmungen von LinkedIn sind unter
              https://www.linkedin.com/legal/privacy-policy abrufbar. Die
              Cookie-Richtlinie von LinkedIn ist unter
              https://www.linkedin.com/legal/cookie-policy abrufbar.
            </p>
            <p className="h5-style mb-2 mt-4">12. Newsletter</p>
            <p className="h6-style mb-2 mt-4">Newsletter&shy;daten</p>
            <p className="dark:text-white">
              Wenn Sie den auf der Website angebotenen Newsletter beziehen
              m&ouml;chten, ben&ouml;tigen wir von Ihnen eine E-Mail-Adresse
              sowie Informationen, welche uns die &Uuml;berpr&uuml;fung
              gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse
              sind und mit dem Empfang des Newsletters einverstanden sind.
              Weitere Daten werden nicht bzw. nur auf freiwilliger Basis
              erhoben. Diese Daten verwenden wir ausschlie&szlig;lich f&uuml;r
              den Versand der angeforderten Informationen und geben diese nicht
              an Dritte weiter.
            </p>
            <p className="dark:text-white">
              Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
              Daten erfolgt ausschlie&szlig;lich auf Grundlage Ihrer
              Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte
              Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie
              deren Nutzung zum Versand des Newsletters k&ouml;nnen Sie
              jederzeit widerrufen, etwa &uuml;ber den
              &bdquo;Austragen&ldquo;-Link im Newsletter. Die
              Rechtm&auml;&szlig;igkeit der bereits erfolgten
              Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf
              unber&uuml;hrt.
            </p>
            <p className="dark:text-white">
              Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns
              hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem
              Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert
              und nach der Abbestellung des Newsletters oder nach Zweckfortfall
              aus der Newsletterverteilerliste gel&ouml;scht. Wir behalten uns
              vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem
              Ermessen im Rahmen unseres berechtigten Interesses nach Art. 6
              Abs. 1 lit. f DSGVO zu l&ouml;schen oder zu sperren.
            </p>
            <p className="dark:text-white">
              Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben
              hiervon unber&uuml;hrt.
            </p>
            <p className="dark:text-white">
              Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
              E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
              einer Blacklist gespeichert, sofern dies zur Verhinderung
              k&uuml;nftiger Mailings erforderlich ist. Die Daten aus der
              Blacklist werden nur f&uuml;r diesen Zweck verwendet und nicht mit
              anderen Daten zusammengef&uuml;hrt. Dies dient sowohl Ihrem
              Interesse als auch unserem Interesse an der Einhaltung der
              gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
              Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die
              Speicherung in der Blacklist ist zeitlich nicht befristet.
              <br />
              Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre
              Interessen unser berechtigtes Interesse &uuml;berwiegen.
            </p>
            <p className="h6-style mb-2 mt-4">
              Newsletterversand an Bestandskunden
            </p>
            <p className="dark:text-white">
              Wenn Sie Waren oder Dienstleistungen bei uns bestellen und hierbei
              Ihre E-Mail-Adresse hinterlegen, kann diese E-Mail-Adresse in der
              Folge durch uns f&uuml;r den Versand von Newslettern verwendet
              werden, sofern wir Sie vorab hier&uuml;ber informieren. In einem
              solchen Fall wird &uuml;ber den Newsletter nur Direktwerbung
              f&uuml;r eigene &auml;hnliche Waren oder Dienstleistungen
              versendet. Die Zusendung dieses Newsletters kann von Ihnen
              jederzeit gek&uuml;ndigt werden. Zu diesem Zweck findet sich in
              jedem Newsletter ein entsprechender Link. Rechtsgrundlage f&uuml;r
              den Versand des Newsletters ist in diesem Fall Art. 6 Abs. 1 lit.
              f DSGVO in Verbindung mit &sect; 7 Abs. 3 UWG.
            </p>
            <p className="dark:text-white">
              Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
              E-Mail-Adresse bei uns ggf. in einer Blacklist gespeichert, um
              k&uuml;nftige Mailings an Sie zu verhindern. Die Daten aus der
              Blacklist werden nur f&uuml;r diesen Zweck verwendet und nicht mit
              anderen Daten zusammengef&uuml;hrt. Dies dient sowohl Ihrem
              Interesse als auch unserem Interesse an der Einhaltung der
              gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
              Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die
              Speicherung in der Blacklist ist zeitlich nicht befristet. Sie
              k&ouml;nnen der Speicherung widersprechen, sofern Ihre Interessen
              unser berechtigtes Interesse &uuml;berwiegen.
            </p>
            <p className="h5-style mb-2 mt-4">13. Plugins und Tools</p>
            <p className="h6-style mb-2 mt-4">YouTube</p>
            <p className="dark:text-white">
              Diese Website bindet Videos der Website YouTube ein. Betreiber der
              Website ist die Google Ireland Limited (&bdquo;Google&ldquo;),
              Gordon House, Barrow Street, Dublin 4, Irland.
            </p>
            <p className="dark:text-white">
              Wenn Sie eine unserer Webseiten besuchen, auf denen YouTube
              eingebunden ist, wird eine Verbindung zu den Servern von YouTube
              hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche
              unserer Seiten Sie besucht haben.
            </p>
            <p className="dark:text-white">
              Des Weiteren kann YouTube verschiedene Cookies auf Ihrem
              Endger&auml;t speichern oder vergleichbare Technologien zur
              Wiedererkennung verwenden (z.&nbsp;B. Device-Fingerprinting). Auf
              diese Weise kann YouTube Informationen &uuml;ber Besucher dieser
              Website erhalten. Diese Informationen werden u.&nbsp;a. verwendet,
              um Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu
              verbessern und Betrugsversuchen vorzubeugen.
            </p>
            <p className="dark:text-white">
              Wenn Sie in Ihrem YouTube-Account eingeloggt sind,
              erm&ouml;glichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
              pers&ouml;nlichen Profil zuzuordnen. Dies k&ouml;nnen Sie
              verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.
            </p>
            <p className="dark:text-white">
              Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
              Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
              Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
              entsprechende Einwilligung abgefragt wurde, erfolgt die
              Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1
              lit. a DSGVO und &sect; 25 Abs. 1 TDDDG, soweit die Einwilligung
              die Speicherung von Cookies oder den Zugriff auf Informationen im
              Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im
              Sinne des TDDDG umfasst. Die Einwilligung ist jederzeit
              widerrufbar.
            </p>
            <p className="dark:text-white">
              Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
              Datenschutzerkl&auml;rung von YouTube unter:
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
            <p className="dark:text-white">
              Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach
              dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist
              ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und
              den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards
              bei Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes
              nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
              Datenschutzstandards einzuhalten. Weitere Informationen hierzu
              erhalten Sie vom Anbieter unter folgendem Link:
              <a
                href="https://www.dataprivacyframework.gov/participant/5780"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.dataprivacyframework.gov/participant/5780
              </a>
              .
            </p>
            <p className="h6-style mb-2 mt-4">Google Fonts</p>
            <p className="dark:text-white">
              Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
              so genannte Google Fonts, die von Google bereitgestellt werden.
              Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten
              Fonts in ihren Browsercache, um Texte und Schriftarten korrekt
              anzuzeigen.
            </p>
            <p className="dark:text-white">
              Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung
              zu den Servern von Google aufnehmen. Hierdurch erlangt Google
              Kenntnis dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese
              Website aufgerufen wurde. Die Nutzung von Google Fonts erfolgt auf
              Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
              ein berechtigtes Interesse an der einheitlichen Darstellung des
              Schriftbildes auf seiner Website. Sofern eine entsprechende
              Einwilligung abgefragt wurde, erfolgt die Verarbeitung
              ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO
              und &sect; 25 Abs. 1 TDDDG, soweit die Einwilligung die
              Speicherung von Cookies oder den Zugriff auf Informationen im
              Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im
              Sinne des TDDDG umfasst. Die Einwilligung ist jederzeit
              widerrufbar.
            </p>
            <p className="dark:text-white">
              Wenn Ihr Browser Google Fonts nicht unterst&uuml;tzt, wird eine
              Standardschrift von Ihrem Computer genutzt.
            </p>
            <p className="dark:text-white">
              Weitere Informationen zu Google Fonts finden Sie unter
              <a
                href="https://developers.google.com/fonts/faq"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://developers.google.com/fonts/faq
              </a>
              und in der Datenschutzerkl&auml;rung von Google:
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
            <p className="dark:text-white">
              Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach
              dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist
              ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und
              den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards
              bei Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes
              nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
              Datenschutzstandards einzuhalten. Weitere Informationen hierzu
              erhalten Sie vom Anbieter unter folgendem Link:
              <a
                href="https://www.dataprivacyframework.gov/participant/5780"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.dataprivacyframework.gov/participant/5780
              </a>
              .
            </p>
            <p className="h6-style mb-2 mt-4">Google Remarketing</p>
            <p className="dark:text-white">
              Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite Dienste von Google Remarketing integriert. Google
              Remarketing ist eine Funktion von Google Ads, die es einem
              Unternehmen ermöglicht, bei solchen Internetnutzern Werbung
              einblenden zu lassen, die sich zuvor auf der Internetseite des
              Unternehmens aufgehalten haben. Die Integration von Google
              Remarketing gestattet es einem Unternehmen demnach, nutzerbezogene
              Werbung zu erstellen und dem Internetnutzer folglich
              interessenrelevante Werbeanzeigen anzeigen zu lassen.
            </p>
            <p className="dark:text-white">
              Betreibergesellschaft der Dienste von Google Remarketing ist die
              Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351,
              USA.
            </p>
            <p className="dark:text-white">
              Zweck von Google Remarketing ist die Einblendung von
              interessenrelevanter Werbung. Google Remarketing ermöglicht es
              uns, Werbeanzeigen über das Google-Werbenetzwerk anzuzeigen oder
              auf anderen Internetseiten anzeigen zu lassen, welche auf die
              individuellen Bedürfnisse und Interessen von Internetnutzern
              abgestimmt sind.
            </p>
            <p className="dark:text-white">
              Google Remarketing setzt ein Cookie auf dem
              informationstechnologischen System der betroffenen Person. Was
              Cookies sind, wurde oben bereits erläutert. Mit der Setzung des
              Cookies wird Google eine Wiedererkennung des Besuchers unserer
              Internetseite ermöglicht, wenn dieser in der Folge Internetseiten
              aufruft, die ebenfalls Mitglied des Google-Werbenetzwerks sind.
              Mit jedem Aufruf einer Internetseite, auf welcher der Dienst von
              Google Remarketing integriert wurde, identifiziert sich der
              Internetbrowser der betroffenen Person automatisch bei Google. Im
              Rahmen dieses technischen Verfahrens erhält Google Kenntnis über
              personenbezogene Daten, wie der IP-Adresse oder des Surfverhaltens
              des Nutzers, welche Google unter anderem zur Einblendung
              interessenrelevanter Werbung verwendet.
            </p>
            <p className="dark:text-white">
              Mittels des Cookies werden personenbezogene Informationen,
              beispielsweise die durch die betroffene Person besuchten
              Internetseiten, gespeichert. Bei jedem Besuch unserer
              Internetseiten werden demnach personenbezogene Daten,
              einschließlich der IP-Adresse des von der betroffenen Person
              genutzten Internetanschlusses, an Google in den Vereinigten
              Staaten von Amerika übertragen. Diese personenbezogenen Daten
              werden durch Google in den Vereinigten Staaten von Amerika
              gespeichert. Google gibt diese über das technische Verfahren
              erhobenen personenbezogenen Daten unter Umständen an Dritte
              weiter.
            </p>
            <p className="dark:text-white">
              Die betroffene Person kann die Setzung von Cookies durch unsere
              Internetseite, wie oben bereits dargestellt, jederzeit mittels
              einer entsprechenden Einstellung des genutzten Internetbrowsers
              verhindern und damit der Setzung von Cookies dauerhaft
              widersprechen. Eine solche Einstellung des genutzten
              Internetbrowsers würde auch verhindern, dass Google ein Cookie auf
              dem informationstechnologischen System der betroffenen Person
              setzt. Zudem kann ein von Google Analytics bereits gesetzter
              Cookie jederzeit über den Internetbrowser oder andere
              Softwareprogramme gelöscht werden.
            </p>
            <p className="dark:text-white">
              Ferner besteht für die betroffene Person die Möglichkeit, der
              interessenbezogenen Werbung durch Google zu widersprechen. Hierzu
              muss die betroffene Person von jedem der von ihr genutzten
              Internetbrowser aus den Link www.google.de/settings/ads aufrufen
              und dort die gewünschten Einstellungen vornehmen.
            </p>
            <p className="dark:text-white">
              Weitere Informationen und die geltenden Datenschutzbestimmungen
              von Google können unter
              https://www.google.de/intl/de/policies/privacy/ abgerufen werden.
            </p>
            <p className="h6-style mb-2 mt-4">Google Ads</p>
            <p className="dark:text-white">
              Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite Google Ads integriert. Google Ads ist ein
              Online-Dienst zur Internetwerbung, der es Werbetreibenden
              gestattet, sowohl Anzeigen in den Suchmaschinenergebnissen von
              Google als auch im Google-Werbenetzwerk zu schalten. Google Ads
              ermöglicht es einem Werbetreibenden, vorab bestimmte
              Schlüsselwörter festzulegen, mittels derer eine Anzeige in den
              Suchmaschinenergebnissen von Google ausschließlich dann angezeigt
              wird, wenn der Nutzer mit der Suchmaschine ein
              schlüsselwortrelevantes Suchergebnis abruft. Im
              Google-Werbenetzwerk werden die Anzeigen mittels eines
              automatischen Algorithmus und unter Beachtung der zuvor
              festgelegten Schlüsselwörter auf themenrelevanten Internetseiten
              verteilt.
            </p>
            <p className="dark:text-white">
              Betreibergesellschaft der Dienste von Google Ads ist die Google
              Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.
            </p>
            <p className="dark:text-white">
              Der Zweck von Google Ads ist die Bewerbung unserer Internetseite
              durch die Einblendung von interessenrelevanter Werbung auf den
              Internetseiten von Drittunternehmen und in den
              Suchmaschinenergebnissen der Suchmaschine Google und eine
              Einblendung von Fremdwerbung auf unserer Internetseite.
            </p>
            <p className="dark:text-white">
              Gelangt eine betroffene Person über eine Google-Anzeige auf unsere
              Internetseite, wird auf dem informationstechnologischen System der
              betroffenen Person durch Google ein sogenannter Conversion-Cookie
              abgelegt. Was Cookies sind, wurde oben bereits erläutert. Ein
              Conversion-Cookie verliert nach dreißig Tagen seine Gültigkeit und
              dient nicht zur Identifikation der betroffenen Person. Über den
              Conversion-Cookie wird, sofern das Cookie noch nicht abgelaufen
              ist, nachvollzogen, ob bestimmte Unterseiten, beispielsweise der
              Warenkorb von einem Online-Shop-System, auf unserer Internetseite
              aufgerufen wurden. Durch den Conversion-Cookie können sowohl wir
              als auch Google nachvollziehen, ob eine betroffene Person, die
              über eine Ads-Anzeige auf unsere Internetseite gelangt ist, einen
              Umsatz generierte, also einen Warenkauf vollzogen oder abgebrochen
              hat.
            </p>
            <p className="dark:text-white">
              Die durch die Nutzung des Conversion-Cookies erhobenen Daten und
              Informationen werden von Google verwendet, um Besuchsstatistiken
              für unsere Internetseite zu erstellen. Diese Besuchsstatistiken
              werden durch uns wiederum genutzt, um die Gesamtanzahl der Nutzer
              zu ermitteln, welche über Ads-Anzeigen an uns vermittelt wurden,
              also um den Erfolg oder Misserfolg der jeweiligen Ads-Anzeige zu
              ermitteln und um unsere Ads-Anzeigen für die Zukunft zu
              optimieren. Weder unser Unternehmen noch andere Werbekunden von
              Google Ads erhalten Informationen von Google, mittels derer die
              betroffene Person identifiziert werden könnte.
            </p>
            <p className="dark:text-white">
              Mittels des Conversion-Cookies werden personenbezogene
              Informationen, beispielsweise die durch die betroffene Person
              besuchten Internetseiten, gespeichert. Bei jedem Besuch unserer
              Internetseiten werden demnach personenbezogene Daten,
              einschließlich der IP-Adresse des von der betroffenen Person
              genutzten Internetanschlusses, an Google in den Vereinigten
              Staaten von Amerika übertragen. Diese personenbezogenen Daten
              werden durch Google in den Vereinigten Staaten von Amerika
              gespeichert. Google gibt diese über das technische Verfahren
              erhobenen personenbezogenen Daten unter Umständen an Dritte
              weiter.
            </p>
            <p className="dark:text-white">
              Die betroffene Person kann die Setzung von Cookies durch unsere
              Internetseite, wie oben bereits dargestellt, jederzeit mittels
              einer entsprechenden Einstellung des genutzten Internetbrowsers
              verhindern und damit der Setzung von Cookies dauerhaft
              widersprechen. Eine solche Einstellung des genutzten
              Internetbrowsers würde auch verhindern, dass Google ein
              Conversion-Cookie auf dem informationstechnologischen System der
              betroffenen Person setzt. Zudem kann ein von Google Ads bereits
              gesetzter Cookie jederzeit über den Internetbrowser oder andere
              Softwareprogramme gelöscht werden.
            </p>
            <p className="dark:text-white">
              Ferner besteht für die betroffene Person die Möglichkeit, der
              interessenbezogenen Werbung durch Google zu widersprechen. Hierzu
              muss die betroffene Person von jedem der von ihr genutzten
              Internetbrowser aus den Link www.google.de/settings/ads aufrufen
              und dort die gewünschten Einstellungen vornehmen.
            </p>
            <p className="dark:text-white">
              Weitere Informationen und die geltenden Datenschutzbestimmungen
              von Google können unter
              https://www.google.de/intl/de/policies/privacy/ abgerufen werden.
            </p>
            <p className="h6-style mb-2 mt-4">Clarity</p>
            <p className="dark:text-white">
              Diese Website nutzt Clarity. Anbieter ist die Microsoft Ireland
              Operations Limited, One Microsoft Place, South County Business
              Park, Leopardstown, Dublin 18, Irland,
              https://docs.microsoft.com/en-us/clarity/ (im Folgenden
              "Clarity").
            </p>
            <p className="dark:text-white">
              Clarity ist ein Werkzeug zur Analyse des Nutzerverhaltens auf
              dieser Website. Hierbei erfasst Clarity insbesondere
              Mausbewegungen und erstellt eine grafische Darstellung darüber,
              auf welchen Teil der Website Nutzer besonders häufig scrollen
              (Heatmaps). Clarity kann ferner Sitzungen aufzeichnen, sodass wir
              die Seitennutzung in Form von Videos ansehen können. Ferner
              erhalten wir Angaben über das allgemeine Nutzerverhalten innerhalb
              unserer Website.
            </p>
            <p className="dark:text-white">
              Clarity verwendet Technologien, die die Wiedererkennung des
              Nutzers zum Zwecke der Analyse des Nutzerverhaltens ermöglichen
              (z. B. Cookies oder Einsatz von Device-Fingerprinting). Ihre
              personenbezogenen Daten werden auf den Servern von Microsoft
              (Microsoft Azure Cloud Service) in den USA gespeichert.
            </p>
            <p className="dark:text-white">
              Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der
              Einsatz des o. g. Dienstes ausschließlich auf Grundlage von Art. 6
              Abs. 1 lit. a DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit
              widerrufbar. Soweit keine Einwilligung eingeholt wurde, erfolgt
              die Verwendung dieses Dienstes auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO; der Websitebetreiber hat ein berechtigtes Interesse
              an einer effektiven Nutzeranalyse.
            </p>
            <p className="dark:text-white">
              Weitere Details zum Datenschutz von Clarity finden Sie hier:
              https://docs.microsoft.com/en-us/clarity/faq
              Widerspruchsmöglichkeit (Opt-Out):
              https://choice.microsoft.com/de-DE/opt-out
            </p>
            <p className="h6-style mb-2 mt-4">Microsoft Advertising</p>
            <p className="dark:text-white">
              Dieser Werbende verwendet Microsoft Advertising (ehemals "Bing
              Ads"), um online für sein Unternehmen zu werben. Anbieter ist
              Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399,
              USA
              https://about.ads.microsoft.com/de-de/h/a/microsoft-advertising
            </p>
            <p className="dark:text-white">
              Er verwendet eine kleine Textdatei, auch als Cookie bekannt, um
              den Abschluss und die Abwicklung von Transaktionen zu erfassen,
              effektiver auf seine Produkte und Dienstleistungen aufmerksam zu
              machen und seine Angebote auf die Bedürfnisse der Nutzer
              abzustimmen. Hierzu verwendet der Werbende auch eine Technologie
              (Conversion-Tracking-Tool) von Microsoft, welche auch Daten des
              Nutzers bezüglich seines Verhaltens auf der Website speichert;
              diese Daten betreffen nur das Userverhalten. Mit dem Microsoft
              Advertising soll sichergestellt werden, dass alle gesammelten
              Daten anonym bleiben und nicht zur Identifizierung von Personen
              verwendet werden können.
            </p>
            <p className="dark:text-white">
              Microsoft selbst nutzt die Daten, um das eigene Angebot zu
              verbessern und speichert dabei unter Umständen Ihre IP-Adresse;
              sollten Sie ein Microsoft-Konto haben, können die erhobenen Daten
              von Microsoft auch mit jenem verknüpft werden. Ihre
              personenbezogenen Daten werden auf den Servern von Microsoft in
              den USA gespeichert.
            </p>
            <p className="dark:text-white">
              Cookies können in Browsern wie Internet Explorer und Mozilla
              Firefox gelöscht werden. Außerdem kann dort ausgewählt werden,
              welche Cookies auf dem Computer zugelassen werden sollen oder
              nicht. Informationen zum Löschen von Cookies oder zum Ändern der
              Datenschutzrichtlinien für den Computer finden Sie in der
              Browserhilfe. Zudem können Sie unter
              https://account.microsoft.com/privacy/ad-settings/signedout die
              Funktion der interessenbezogenen Werbeanzeigen von Microsoft
              Advertising ausschalten.
              https://privacy.google.com/businesses/controllerterms/mccs/.
            </p>
            <p className="h6-style mb-2 mt-4">Google Ads Remarketing</p>
            <p className="dark:text-white">
              Diese Website nutzt die Funktionen von Google Ads Remarketing.
              Anbieter ist die Google Ireland Limited ("Google"), Gordon House,
              Barrow Street, Dublin 4, Irland.
            </p>
            <p className="dark:text-white">
              Mit Google Ads Remarketing können wir Personen, die mit unserem
              Online-Angebot interagieren bestimmten Zielgruppen zuordnen, um
              ihnen anschließend interessenbezogene Werbung im
              GoogleWerbenetzwerk anzeigen zu lassen (Remarketing bzw.
              Retargeting).
            </p>
            <p className="dark:text-white">
              Des Weiteren können die mit Google Ads Remarketing erstellten
              Werbe-Zielgruppen mit den geräteübergreifenden Funktionen von
              Google verknüpft werden. Auf diese Weise können
              interessenbezogene, personalisierte Werbebotschaften, die in
              Abhängigkeit Ihres früheren Nutzungs- und Surfverhaltens auf einem
              Endgerät (z. B. Handy) an Sie angepasst wurden auch auf einem
              anderen Ihrer Endgeräte (z. B. Tablet oder PC) angezeigt werden.
            </p>
            <p className="dark:text-white">
              Wenn Sie über einen Google-Account verfügen, können Sie der
              personalisierten Werbung unter folgendem Link widersprechen:
              https://www.google.com/settings/ads/onweb/.
            </p>
            <p className="dark:text-white">
              Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer
              Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
              TTDSG. Die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p className="dark:text-white">
              Weitergehende Informationen und die Datenschutzbestimmungen finden
              Sie in der Datenschutzerklärung von Google unter:
              https://policies.google.com/technologies/ads?hl=de.
            </p>
            <p className="h6-style mb-2 mt-4">Google Conversion-Tracking</p>
            <p className="dark:text-white">
              Diese Website nutzt Google Conversion Tracking. Anbieter ist die
              Google Ireland Limited ("Google"), Gordon House, Barrow Street,
              Dublin 4, Irland.
            </p>
            <p className="dark:text-white">
              Mit Hilfe von Google-Conversion-Tracking können Google und wir
              erkennen, ob der Nutzer bestimmte Aktionen durchgeführt hat. So
              können wir beispielsweise auswerten, welche Buttons auf unserer
              Website wie häufig geklickt und welche Produkte besonders häufig
              angesehen oder gekauft wurden. Diese Informationen dienen dazu,
              Conversion-Statistiken zu erstellen. Wir erfahren die Gesamtanzahl
              der Nutzer, die auf unsere Anzeigen geklickt haben und welche
              Aktionen sie durchgeführt haben. Wir erhalten keine Informationen,
              mit denen wir den Nutzer persönlich identifizieren können. Google
              selbst nutzt zur Identifikation Cookies oder vergleichbare
              Wiedererkennungstechnologien.
            </p>
            <p className="dark:text-white">
              Wir nutzen die Funktion des erweiterten Conversion Trackings
              innerhalb des Google Conversion Trackings.
            </p>
            <p className="dark:text-white">
              Der erweiterte Abgleich ermöglicht es uns, verschiedene Arten von
              Daten (z. B. E-Mail-Adresse, Name, Adressen oder Telefonnummer)
              unserer Kunden und Interessenten, die wir über unsere Website
              sammeln, an Google zu übermitteln. Diese Daten können in Ihren
              Conversion-Tracking-Tags erfasst, unter Einsatz eines sicheren
              Einweg-Hash-Algorithmus gehasht und als Hash-Daten an Google
              gesendet und dann zur Verbesserung der Conversion-Messung
              verwendet werden. Zudem können wir durch diese Aktivierung unsere
              Werbekampagnen auf noch präziser auf Personen zuschneiden, die
              sich für unsere Angebote interessieren.
            </p>
            <p className="dark:text-white">
              Mehr Informationen zum erweiterten Conversion Tracking bei Google
              finden Sie hier:
              https://support.google.com/google-ads/answer/9888656?hl=de-GB
            </p>
            <p className="dark:text-white">
              Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer
              Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und §25 Abs. 1 TTDSG.
              Die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p className="dark:text-white">
              Mehr Informationen zu Google Conversion-Tracking finden Sie in den
              Datenschutzbestimmungen von Google:
              https://policies.google.com/privacy?hl=de.
            </p>
            <p className="h6-style mb-2 mt-4">
              Meta-Pixel (ehemals Facebook Pixel)
            </p>
            <p className="dark:text-white">
              Diese Website nutzt zur Konversionsmessung der
              Besucheraktions-Pixel von Facebook/Meta. Anbieter dieses Dienstes
              ist die Meta Platforms Ireland Limited, 4 Grand Canal Square,
              Dublin 2, Irland. Die erfassten Daten werden nach Aussage von
              Facebook jedoch auch in die USA und in andere Drittländer
              übertragen.
            </p>
            <p className="dark:text-white">
              So kann das Verhalten der Seitenbesucher nachverfolgt werden,
              nachdem diese durch Klick auf eine Facebook-Werbeanzeige auf die
              Website des Anbieters weitergeleitet wurden. Dadurch können die
              Wirksamkeit der Facebook-Werbeanzeigen für statistische und
              Marktforschungszwecke ausgewertet werden und zukünftige
              Werbemaßnahmen optimiert werden.
            </p>
            <p className="dark:text-white">
              Die erhobenen Daten sind für uns als Betreiber dieser Website
              anonym, wir können keine Rückschlüsse auf die Identität der Nutzer
              ziehen. Die Daten werden aber von Facebook gespeichert und
              verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil
              möglich ist und Facebook die Daten für eigene Werbezwecke,
              entsprechend der Facebook-Datenverwendungsrichtlinie unter
              https://de-de.facebook.com/about/privacy/ verwenden kann. Dadurch
              kann Facebook das Schalten von Werbeanzeigen auf Seiten von
              Facebook sowie außerhalb von Facebook ermöglichen. Diese
              Verwendung der Daten kann von uns als Seitenbetreiber nicht
              beeinflusst werden.
            </p>
            <p className="dark:text-white">
              Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer
              Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
              TTDSG. Die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p className="dark:text-white">
              Wir nutzen die Funktion des erweiterten Abgleichs innerhalb der
              Meta-Pixel.
            </p>
            <p className="dark:text-white">
              Der erweiterte Abgleich ermöglicht uns, verschiedene Arten von
              Daten (z. B. Wohnort, Bundesland, Postleitzahl, gehashte
              E-Mail-Adressen, Namen, Geschlecht, Geburtsdatum oder
              Telefonnummer) unserer Kunden und Interessenten, die wir über
              unsere Website sammeln an Meta (Facebook) zu übermitteln. Durch
              diese Aktivierung können wir unsere Werbekampagnen auf Facebook
              noch präziser auf Personen zuschneiden, die sich für unsere
              Angebote interessieren. Außerdem verbessert der erweiterte
              Abgleich die Zuordnung von Webseiten-Conversions und erweitert
              Custom Audiences.
            </p>
            <p className="dark:text-white">
              Weitere Informationen zum erweiterten Abgleichs innerhalb der
              Meta-Pixel finden Sie hier:
              https://www.facebook.com/business/help/611774685654668?id=1205376682832142.
            </p>
            <p className="dark:text-white">
              Soweit mit Hilfe des hier beschriebenen Tools personenbezogene
              Daten auf unserer Website erfasst und an Facebook weitergeleitet
              werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand
              Canal Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für
              diese Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die
              gemeinsame Verantwortlichkeit beschränkt sich dabei ausschließlich
              auf die Erfassung der Daten und deren Weitergabe an Facebook. Die
              nach der Weiterleitung erfolgende Verarbeitung durch Facebook ist
              nicht Teil der gemeinsamen Verantwortung. Die uns gemeinsam
              obliegenden Verpflichtungen wurden in einer Vereinbarung über
              gemeinsame Verarbeitung festgehalten. Den Wortlaut der
              Vereinbarung finden Sie
              unter:https://www.facebook.com/legal/controller_addendum.
            </p>
            <p className="dark:text-white">
              Laut dieser Vereinbarung sind wir für die Erteilung der
              Datenschutzinformationen beim Einsatz des Facebook-Tools und für
              die datenschutzrechtlich sichere Implementierung des Tools auf
              unserer Website verantwortlich. Für die Datensicherheit der
              Facebook-Produkte ist Facebook verantwortlich. Betroffenenrechte
              (z. B. Auskunftsersuchen) hinsichtlich der bei Facebook
              verarbeiteten Daten können Sie direkt bei Facebook geltend machen.
              Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir
              verpflichtet, diese an Facebook weiterzuleiten.
            </p>
            <p className="dark:text-white">
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              https://www.facebook.com/legal/EU_data_transfer_addendum und
              https://de-de.facebook.com/help/566994660333381
            </p>
            <p className="dark:text-white">
              In den Datenschutzhinweisen von Facebook finden Sie weitere
              Hinweise zum Schutz Ihrer Privatsphäre:
              https://de-de.facebook.com/about/privacy/
            </p>
            <p className="dark:text-white">
              Sie können außerdem die Remarketing-Funktion "Custom Audiences" im
              Bereich Einstellungen für Werbeanzeigen unter
              https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
              deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein.
            </p>
            <p className="dark:text-white">
              Wenn Sie kein Facebook Konto besitzen, können Sie nutzungsbasierte
              Werbung von Facebook auf der Website der European Interactive
              Digital Advertising Alliance deaktivieren:
              http://www.youronlinechoices.com/de/praferenzmanagement/.
            </p>
            <p className="dark:text-white">
              Das Unternehmen verfügt über eine Zertifizierung nach dem "EU-US
              Data Privacy Framework" (DPF). Der DPF ist ein Übereinkommen
              zwischen der Europäischen Union und den USA, der die Einhaltung
              europäischer Datenschutzstandards bei Datenverarbeitungen in den
              USA gewährleisten soll. Jedes nach dem DPF zertifizierte
              Unternehmen verpflichtet sich, diese Datenschutzstandards
              einzuhalten. Weitere Informationen hierzu erhalten Sie vom
              Anbieter unter folgendem Link:
              https://www.dataprivacyframework.gov/s/participant-search/participantdetail?contact=true&id=a2zt0000000GnywAAC&status=Active
            </p>
            <p className="h6-style mb-2 mt-4">Facebook Custom Audiences</p>
            <p className="dark:text-white">
              Wir nutzen Facebook Custom Audiences. Anbieter dieses Dienstes ist
              die Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin
              2, Irland.
            </p>
            <p className="dark:text-white">
              Wenn Sie unsere Webseiten und Apps besuchen bzw. nutzen, unsere
              kostenlosen oder kostenpflichtigen Angebote in Anspruch nehmen,
              Daten an uns übermitteln oder mit den Facebook-Inhalten unseres
              Unternehmens interagieren, erfassen wir hierbei Ihre
              personenbezogenen Daten. Sofern Sie uns eine Einwilligung zur
              Nutzung von Facebook Custom Audiences erteilen, werden wir diese
              Daten an Facebook übermitteln, mit denen Facebook Ihnen passende
              Werbung ausspielen kann. Des Weiteren können mit Ihren Daten
              Zielgruppen definiert werden (Lookalike Audiences).
            </p>
            <p className="dark:text-white">
              Facebook verarbeitet diese Daten als unser Auftragsverarbeiter.
              Details sind der Nutzungsvereinbarung von Facebook zu entnehmen:
              https://www.facebook.com/legal/terms/customaudience.
            </p>
            <p className="dark:text-white">
              Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer
              Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
              TTDSG. Die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p className="dark:text-white">
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              https://www.facebook.com/legal/terms/customaudience und
              https://www.facebook.com/legal/terms/dataprocessing.
            </p>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Datenschutzerklaerung;
