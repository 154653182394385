import { useState, ReactNode } from "react";
import { Button } from "@/components/ui/button.tsx";

interface LocalCompaniesDetails {
  company_logo_url?: string;
  company_name: string;
  linkedin?: string;
  twitter?: string; // Added twitter property
  facebook?: string;
  instagram?: string;
  youtube?: string;
  address?: string;
  postal_code?: string;
  city?: string;
  company_url?: string;
  email_address?: string;
  phone_number?: string;
  nace_codes?: { description_de: string }[];
  short_description_de?: string;
  vat_id?: string;
  full_description_de?: string;
  fax_number?: string;
  directors?: string[];
  registration_number?: string;
  registration_court?: string;
}

function CompaniesContent({
  companiesDetails,
}: {
  companiesDetails: LocalCompaniesDetails;
}) {
  const [showMore, setShowMore] = useState(false);
  const SocialIcon = ({ children }: { children: ReactNode }) => (
    <svg
      className="h-5 w-5 text-gray-800 dark:text-white"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {children}
    </svg>
  );

  const YoutubeIcon = () => (
    <SocialIcon>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z"
        clipRule="evenodd"
      />
    </SocialIcon>
  );

  const FacebookIcon = () => (
    <SocialIcon>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z"
        clipRule="evenodd"
      />
    </SocialIcon>
  );

  const InstagramIcon = () => (
    <SocialIcon>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
        clipRule="evenodd"
      />
    </SocialIcon>
  );

  const LinkedinIcon = () => (
    <SocialIcon>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M7.2 8.809H4V19.5h3.2V8.809Z" />
    </SocialIcon>
  );

  const TwitterIcon = () => (
    <SocialIcon>
      <path
        fill="currentColor"
        d="M13.795 10.533 20.68 2h-3.073l-5.255 6.517L7.69 2H1l7.806 10.91L1.47 22h3.074l5.705-7.07L15.31 22H22l-8.205-11.467Zm-2.38 2.95L9.97 11.464 4.36 3.627h2.31l4.528 6.317 1.443 2.02 6.018 8.409h-2.31l-4.934-6.89Z"
      />
    </SocialIcon>
  );

  const renderField = (
    label: string,
    content: React.ReactNode,
    id?: string,
  ) => {
    if (!content) return null;
    return (
      <div className="">
        <span className="text-sm font-bold">{label}</span>{" "}
        <span className="text-sm" id={id}>
          {content}
        </span>
      </div>
    );
  };

  const renderBlock = (fields: React.ReactNode) => {
    return <div className="mb-4 last:mb-0">{fields}</div>;
  };
  return (
    <div
      className="flex w-full flex-col gap-4 rounded-md border border-gray-200 p-4 md:flex-row"
      id="company-content"
    >
      {companiesDetails.company_logo_url && (
        <div
          id="company-logo"
          className="h-28 w-28 flex-shrink-0 rounded border border-gray-200 bg-white bg-[length:90%] bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${companiesDetails.company_logo_url})`,
          }}
        />
      )}
      <div className="flex w-full flex-col">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-bold" id="company-name">
            {companiesDetails.company_name}
          </h2>
          <div className="flex">
            {companiesDetails.linkedin && (
              <Button asChild variant="ghost" size="icon">
                <a
                  href={companiesDetails.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedinIcon />
                </a>
              </Button>
            )}
            {companiesDetails.twitter && (
              <Button asChild variant="ghost" size="icon">
                <a
                  href={companiesDetails.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon />
                </a>
              </Button>
            )}
            {companiesDetails.facebook && (
              <Button asChild variant="ghost" size="icon">
                <a
                  href={companiesDetails.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon />
                </a>
              </Button>
            )}
            {companiesDetails.instagram && (
              <Button asChild variant="ghost" size="icon">
                <a
                  href={companiesDetails.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </a>
              </Button>
            )}
            {companiesDetails.youtube && (
              <Button asChild variant="ghost" size="icon">
                <a
                  href={companiesDetails.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YoutubeIcon />
                </a>
              </Button>
            )}
          </div>
        </div>
        {companiesDetails.address && (
          <div className="mb-3 text-sm">
            {companiesDetails.address} <br />
            {companiesDetails.postal_code} {companiesDetails.city}
          </div>
        )}

        {renderBlock(
          <>
            {renderField(
              "Website:",
              companiesDetails.company_url && (
                <a
                  href={`https://${companiesDetails.company_url}`}
                  className="break-words text-blue-700"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {companiesDetails.company_url}
                </a>
              ),
              "company-website",
            )}
            {renderField(
              "E-Mail:",
              companiesDetails.email_address && (
                <a
                  href={`mailto:${companiesDetails.email_address}`}
                  className="break-words text-blue-700"
                >
                  {companiesDetails.email_address}
                </a>
              ),
              "company-email",
            )}
            {renderField(
              "Rufnummer:",
              companiesDetails.phone_number && (
                <a
                  href={`tel:${companiesDetails.phone_number}`}
                  className="text-blue-700 hover:underline"
                >
                  {companiesDetails.phone_number.replace(/^(\+49)/, "$1 ")}
                </a>
              ),
              "company-phone",
            )}
          </>,
        )}

        {renderBlock(
          renderField(
            "Branchen:",
            companiesDetails.nace_codes &&
              companiesDetails.nace_codes.length > 0 && (
                <div className="mt-1 flex flex-wrap gap-2">
                  {Array.from(
                    new Set(
                      companiesDetails.nace_codes.map(
                        (code: { description_de: string }) =>
                          code.description_de,
                      ),
                    ),
                  ).map((industry, index) => (
                    <span
                      key={index}
                      className="rounded bg-webmetic_light-100 px-2.5 py-0.5 text-xs font-medium dark:bg-blue-900 dark:text-blue-300"
                    >
                      {industry}
                    </span>
                  ))}
                </div>
              ),
            "company-industry",
          ),
        )}

        {renderBlock(
          renderField(
            "Kurzbeschreibung:",
            companiesDetails.short_description_de,
            "company-short-description",
          ),
        )}

        {(companiesDetails.vat_id || companiesDetails.full_description_de) && (
          <>
            <button
              className={`flex cursor-pointer items-center gap-1 text-sm font-semibold text-blue-700 hover:underline ${showMore ? "hidden" : ""}`}
              onClick={() => setShowMore(true)}
            >
              Weitere Details
              <svg
                aria-hidden="true"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="m19 9-7 7-7-7"
                />
              </svg>
            </button>

            {showMore && (
              <>
                {renderBlock(
                  <>
                    {renderField(
                      "Fax:",
                      companiesDetails.fax_number,
                      "company-fax",
                    )}
                    {renderField(
                      "Direktoren:",
                      companiesDetails.directors &&
                        companiesDetails.directors.join(", "),
                      "company-directors",
                    )}
                    {renderField(
                      "Handelsregister:",
                      (companiesDetails.registration_number ||
                        companiesDetails.registration_court) &&
                        `${companiesDetails.registration_number || ""} ${companiesDetails.registration_court ? `(${companiesDetails.registration_court})` : ""}`.trim(),
                      "company-registration",
                    )}
                    {renderField(
                      "USt-IdNr.:",
                      companiesDetails.vat_id,
                      "company-business-id",
                    )}
                  </>,
                )}

                {renderBlock(
                  renderField(
                    "Beschreibung:",
                    companiesDetails.full_description_de,
                    "company-description",
                  ),
                )}

                <button
                  className="mt-2 flex cursor-pointer items-center gap-1 text-sm font-semibold text-blue-700 hover:underline"
                  onClick={() => setShowMore(false)}
                >
                  Ausblenden
                  <svg
                    className="rotate-180"
                    aria-hidden="true"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                </button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CompaniesContent;
