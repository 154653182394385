import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Drawer,
  Typography,
  IconButton,
  Textarea,
} from "@material-tailwind/react";
import { verifyMe } from "/src/helpers/verify-me"; // Update this import path

// Access the environment variable
const API_KEY = import.meta.env.internal_api_key;

function SupportDrawer({ open, onClose }) {
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    try {
      const decodedToken = verifyMe();
      setUserData(decodedToken);
    } catch (error) {
      console.error("Error verifying user:", error);
      // Handle the error appropriately (e.g., redirect to login page)
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userData) {
      alert("User data not available. Please log in again.");
      return;
    }
    setIsSubmitting(true);

    try {
      // Push event to data layer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "contact_form",
        formType: "dashboard_contact",
      });

      // Prepare the request body
      const requestBody = {
        mail: {
          to_email: "ys@intent.de",
        },
        content: {
          subject: "Kontakt Formular",
          content: `Account ID: ${userData.account_id} <br /> E-Mail: ${userData.email} <br />Vorname: ${userData.first_name} <br /> Nachname: ${userData.last_name}<br /> Inhalt: ${message}`,
        },
      };

      // Send request to your FastAPI endpoint
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API}/api/email/send-generic`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "api-key": API_KEY,
          },
        },
      );

      if (response.status === 200) {
        alert("Nachricht erfolgreich gesendet!");
        setMessage("");
        onClose();
      } else {
        alert(
          "Es gab einen Fehler beim Senden der Nachricht. Bitte melde dich direkt bei ys@webmetic.de",
        );
      }
    } catch (error) {
      console.error("Error:", error);
      alert(
        "Es gab einen Fehler beim Senden der Nachricht. Bitte melde dich direkt bei ys@webmetic.de",
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="right"
      className="h-full overflow-y-auto border-l drop-shadow-lg sm:w-full md:w-2/3 lg:w-1/2 2xl:w-1/3"
      size={10000}
      overlay={true}
      overlayProps={{
        className: "fixed inset-0 bg-black bg-opacity-60",
      }}
    >
      <div className="-mt-[1px] flex items-center justify-between border-b px-4 py-3">
        <Typography variant="h4" color="blue-gray">
          Support
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <div className="p-4">
        <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
          Kontakt aufnehmen
        </h2>
        <p className="mb-6 text-gray-500 dark:text-gray-400">
          Gerne stehen wir Ihnen bei Fragen oder Problemen zur Seite.
          <br /> Wir freuen uns auf Ihre Nachricht.
        </p>

        <p className="mb-4 font-light text-gray-500 dark:text-gray-400"></p>
        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Nachricht
            </label>
            <textarea
              id="message"
              rows="6"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="btn"
            disabled={isSubmitting || !userData}
          >
            {isSubmitting ? "Wird gesendet..." : "Nachricht absenden"}
          </button>
        </form>
      </div>
    </Drawer>
  );
}

export default SupportDrawer;
