import { LoginRequestData, apiHandlers } from "@/api";
import { MutationResult } from "@/types";
import { useMutation } from "@tanstack/react-query";

type UseAuthMutationsReturn = {
  loginMutations: MutationResult<unknown, LoginRequestData>;
};

export const useAuthMutations = (): UseAuthMutationsReturn => {
  const loginMutations = useMutation({ mutationFn: apiHandlers.auth.login });

  return {
    loginMutations,
  };
};
