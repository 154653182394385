import { CompanyListResponseData, ListCompanyParams, apiHandlers } from "@/api";
import { PAGINATION_LIMIT, COMPANY_LIST } from "@/constants";
import {
  InfiniteData,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from "@tanstack/react-query";

export const useCompanyListQuery = (
  params: ListCompanyParams,
  enabled: boolean,
): UseInfiniteQueryResult<InfiniteData<CompanyListResponseData>, Error> => {
  return useInfiniteQuery({
    queryKey: [
      COMPANY_LIST,
      params.domain,
      params.page,
      params.page_size,
      params.from_date,
      params.to_date,
    ],
    queryFn: ({ pageParam }) => {
      return apiHandlers.company
        .list({ ...params, page: pageParam, page_size: PAGINATION_LIMIT })
        .then((res) => {
          return res.data;
        });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const { isLast, current } = lastPage.pagination[0];
      return isLast ? undefined : current + 1;
    },
    getPreviousPageParam: (firstPage) => {
      const { current, isFirst } = firstPage.pagination[0];

      return isFirst ? undefined : current - 1;
    },
    enabled,
  });
};
