import dataAnalysis from "../../assets/images/data_analysis.svg";
import dataPreview from "../../assets/images/data_preview.svg";

const Features = () => {
  return (
    <section className="border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl space-y-12 px-4 py-8 sm:py-16 lg:space-y-20 lg:px-6">
        <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
          <div className="text-gray-500 dark:text-gray-400 sm:text-lg">
            <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
              1. Potenzielle Leads identifizieren
            </h2>
            <p className="mb-8 font-light lg:text-xl">
              Lassen sie sich keine potenziellen Geschäftskontakte mehr
              entgehen: Webmetic identifiziert mit Hilfe von State-of-the Art
              Algorithmen die tatsächlichen Unternehmen hinter den anonymen
              Besuchern auf Ihrer Website. Sogar wenn deren Mitarbeiter aus dem
              Home-Office darauf zugreifen.
            </p>
            <ul
              role="list"
              className="my-7 space-y-5 border-t border-gray-200 pt-8 dark:border-gray-700"
            >
              <li className="flex space-x-3">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-webmetic_dark dark:text-webmetic_light"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Unternehmen identifizieren
                </span>
              </li>
              <li className="flex space-x-3">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-webmetic_dark dark:text-webmetic_light"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Unternehmen zuordnen
                </span>
              </li>
              <li className="flex space-x-3">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-webmetic_dark dark:text-webmetic_light"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Vertriebschancen maximieren
                </span>
              </li>
            </ul>
          </div>
          <img
            className="mb-4 hidden w-full rounded-lg lg:mb-0 lg:flex"
            src={dataAnalysis}
            alt="Leads identifizieren"
          />
        </div>
        <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
          <img
            className="mb-4 hidden w-full rounded-lg lg:mb-0 lg:flex"
            src={dataPreview}
            alt="Leads analysieren"
          />
          <div className="text-gray-500 dark:text-gray-400 sm:text-lg">
            <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
              2. Potenzielle Leads analysieren
            </h2>
            <p className="mb-8 font-light lg:text-xl">
              Webmetic liefert Ihnen eine detaillierte Verhaltensanalyse der
              Besucher. So erkennen Sie die Bedürfnisse Ihrer potenziellen
              Neukunden, bevor Sie auch nur ein Wort mit ihnen gewechselt haben.
              Eine optimale Grundlage für eine zielgerichtete
              Marketingstrategie.
            </p>
            <ul
              role="list"
              className="my-7 space-y-5 border-t border-gray-200 pt-8 dark:border-gray-700"
            >
              <li className="flex space-x-3">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-webmetic_dark dark:text-webmetic_light"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Nutzerverhalten ganzheitlich erfassen
                </span>
              </li>
              <li className="flex space-x-3">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-webmetic_dark dark:text-webmetic_light"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Technische Nutzungsdetails analysieren
                </span>
              </li>
              <li className="flex space-x-3">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-webmetic_dark dark:text-webmetic_light"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Marketing-Performance präzise messen
                </span>
              </li>
              <li className="flex space-x-3">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-webmetic_dark dark:text-webmetic_light"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Demografische Insights gewinnen
                </span>
              </li>
              <li className="flex space-x-3">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-webmetic_dark dark:text-webmetic_light"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Individuelle Nutzer-Journeys nachverfolgen
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
