import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { useEffect, useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import {
  format,
  isSameDay,
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  parseISO,
  isValid,
} from "date-fns";
import useUser from "@/hooks/useUser.ts";
import { useGetSegmentsListQuery, useSegmentMutations } from "@/hooks/index.ts";
import { Segment } from "@/types/segment.ts";
import {
  CompaniesFilterDate,
  companiesFilterQueryOptions,
} from "@/routes/companies/companies-filter/companies-filter.loader";
import { SEGMENTS_LIST } from "@/constants";

const toValidRangeDate = (dateString: string | null): Date | undefined => {
  if (!dateString) return undefined;
  const parsedDate = parseISO(dateString);
  return isValid(parsedDate) ? parsedDate : undefined;
};

export const useCompaniesFilterView = () => {
  const user = useUser();
  const params = useParams();
  const navigate = useNavigate();
  const { data: list } = useSuspenseQuery(
    companiesFilterQueryOptions(params.domain!),
  );
  const queryClient = useQueryClient();

  const { data: segmentsList } = useGetSegmentsListQuery();
  const { deleteSegmentMutation } = useSegmentMutations();

  const [searchParams, setSearchParams] = useSearchParams();
  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: toValidRangeDate(searchParams.get("from_date")) || undefined,
    to: toValidRangeDate(searchParams.get("to_date")) || undefined,
  });
  const [selectedSegmentId, setSelectedSegmentId] = useState<string>("");
  const [editableSegment, setEditableSegment] = useState<Segment | null>(null);

  const handleDeleteSegment = (id: string) => {
    deleteSegmentMutation(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [SEGMENTS_LIST] });
      },
    });
  };

  const handleSetSelectedSegmentId = (segmentId: string) => {
    setSelectedSegmentId(segmentId);
  };

  const handleResetSelectedSegmentId = () => {
    setSelectedSegmentId("");
  };

  const handleSetEditableSegment = (segment: Segment) => {
    setEditableSegment(segment);
    handleResetSelectedSegmentId();
  };

  const handleResetEditableSegment = () => {
    setEditableSegment(null);
  };

  const resetSelectedCompany = (domain = params.domain) => {
    const n = new URLSearchParams(searchParams);
    n.delete("company_id");
    navigate({
      pathname: `/dashboard/${domain}/companies`,
      search: n.toString(),
    });
  };

  const getDateRange = (item: CompaniesFilterDate): DateRange => {
    const today = new Date();
    switch (item.label) {
      case "today":
        return { from: today, to: today };
      case "yesterday":
        const yesterday = subDays(today, 1);
        return { from: yesterday, to: yesterday };
      case "this_week":
        return { from: startOfWeek(today), to: endOfWeek(today) };
      case "last_week":
        const lastWeekStart = startOfWeek(subDays(today, 7));
        return { from: lastWeekStart, to: endOfWeek(lastWeekStart) };
      case "this_month":
        return { from: startOfMonth(today), to: endOfMonth(today) };
      case "this_year":
        return { from: startOfYear(today), to: endOfYear(today) };
      case "all_time":
        // You might want to set a specific start date for 'all_time'
        return { from: new Date(2000, 0, 1), to: today };
      default:
        // For specific months or years
        if (
          item.label.match(
            /^(january|february|march|april|may|june|july|august|september|october|november|december)$/,
          )
        ) {
          const year = today.getFullYear();
          const month = [
            "january",
            "february",
            "march",
            "april",
            "may",
            "june",
            "july",
            "august",
            "september",
            "october",
            "november",
            "december",
          ].indexOf(item.label);
          const start = new Date(year, month, 1);
          return { from: start, to: endOfMonth(start) };
        }
        if (item.label.match(/^\d{4}$/)) {
          const year = parseInt(item.label, 10);
          return { from: new Date(year, 0, 1), to: new Date(year, 11, 31) };
        }
        // Default case: use the provided dates
        return {
          from: toValidRangeDate(item.date_start) || undefined,
          to: toValidRangeDate(item.date_end) || undefined,
        };
    }
  };

  const handleSelectDateRangeOption = (item: CompaniesFilterDate) => () => {
    const range = getDateRange(item);
    updateSearchParamsDateRange(range);
  };

  const updateSearchParamsDateRange = (dr?: DateRange) => {
    resetSelectedCompany();
    setSearchParams((p) => {
      const n = new URLSearchParams(p);
      if (dr?.from) n.set("from_date", format(dr.from, "yyyy-MM-dd"));
      else n.delete("from_date");
      if (dr?.to) n.set("to_date", format(dr.to, "yyyy-MM-dd"));
      else n.delete("to_date");
      return n;
    });
  };
  const handleUpdateSearchParams = () => updateSearchParamsDateRange(dateRange);

  useEffect(() => {
    setDateRange({
      from: toValidRangeDate(searchParams.get("from_date")) || undefined,
      to: toValidRangeDate(searchParams.get("to_date")) || undefined,
    });
  }, [searchParams]);

  const selectedItem = useMemo(() => {
    const f = toValidRangeDate(searchParams.get("from_date"));
    const t = toValidRangeDate(searchParams.get("to_date"));
    if (!f || !t) return null;
    for (const item of list) {
      const dateStart = parseISO(item.date_start);
      const dateEnd = parseISO(item.date_end);
      if (isSameDay(dateStart, f) && isSameDay(dateEnd, t)) {
        return item;
      }
    }
    return null;
  }, [searchParams, list]);

  return {
    user,
    list,
    params,
    segmentsList,
    dateRange,
    selectedSegmentId,
    editableSegment,
    setDateRange,
    resetSelectedCompany,
    handleDeleteSegment,
    handleSetSelectedSegmentId,
    handleResetSelectedSegmentId,
    handleSetEditableSegment,
    handleResetEditableSegment,
    handleSelectDateRangeOption,
    handleUpdateSearchParams,
    selectedItem,
  };
};
