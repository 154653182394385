import { z } from "zod";

export const segmentFormSchema = z.object({
  label: z.string().nonempty("Label is required"),
  session: z.object({
    utm_medium: z.string().optional(),
    utm_campaign: z.string().optional(),
    utm_term: z.string().optional(),
    utm_content: z.string().optional(),
    utm_campaign_id: z.string().optional(),
    session_duration: z.string().optional(),
    session_time: z.string().optional(),
  }),
  user_data: z.object({
    document_location: z.string().url("Must be a valid URL").optional(),
    document_title: z.string().optional(),
    scroll_depth: z.string().optional(),
  }),
  company: z.object({
    name: z.string().nonempty("Company name is required"),
    postal: z.string().optional(),
    city: z.string().optional(),
    country: z.string().optional(),
    nace: z.object({
      code: z.string().optional(),
      section: z.string().optional(),
      division: z.string().optional(),
      activity: z.string().optional(),
    }),
  }),
});

export type SegmentFormSchemaType = z.infer<typeof segmentFormSchema>;
