import HeroHeaderDmexco from "./HeroHeaderDmexco";
import Features from "../startpage/Features";
import { CTA_1, CTA_2, CTA_3 } from "../components/CallToAction";
import DataOverview from "../startpage/DataOverview";
import EasyImplementation from "../startpage/EasyImplementation";
import Layout from "../components/Layout";
import Customers from "../components/CustomersLogos";
import Usps from "../features/Usps";

const StartPage = () => {
  return (
    <Layout>
      <HeroHeaderDmexco />
      <Customers />
      <Features />
      <Usps />
      <CTA_3 />
      <DataOverview />
      <EasyImplementation />
    </Layout>
  );
};

export default StartPage;
