import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const Impressum = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <section className="bg-white py-8 dark:bg-gray-900 lg:py-24">
          <div className="format dark:format-invert mx-auto max-w-4xl px-4 lg:px-4">
            <h1 className="h2-style mb-2">Impressum</h1>
            <p className="mb-6 text-lg text-gray-600 dark:text-gray-400 lg:text-lg">
              Zuletzt aktualisiert: 09.10.2024
            </p>

            <p className="dark:text-white">
              Webmetic ist ein Projekt der Intent GmbH
            </p>

            <p className="h6-style my-4 mb-2 mt-4">Angaben gemäß § 5 TMG:</p>
            <p className="dark:text-white">
              Intent GmbH
              <br />
              Orionweg 5<br />
              85609 Aschheim
            </p>

            <p className="h6-style my-4 mb-2 mt-4">Kontakt:</p>
            <p className="dark:text-white">
              Telefon: +49 89 3183 5253
              <br />
              E-Mail: mail@webmetic.de
            </p>

            <p className="h6-style my-4 mb-2 mt-4">Rechtliche Angaben:</p>
            <p className="dark:text-white">
              Vertretungsberechtiger Geschäftsführer: Yannik Süß
              <br />
              Registergericht: Amtsgericht München
              <br />
              HRB: 222605
            </p>

            <p className="h6-style my-4 mb-2 mt-4">Umsatzsteuer-ID:</p>
            <p className="dark:text-white">
              Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
              DE305338170
            </p>

            <p className="h6-style my-4 mb-2 mt-4">Weitere Angaben:</p>
            <p className="dark:text-white">
              Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit:{" "}
              <a
                href="http://ec.europa.eu/consumers/odr"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://ec.europa.eu/consumers/odr
              </a>
              <br />
              Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </p>
            <p className="dark:text-white">
              Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.
            </p>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Impressum;
