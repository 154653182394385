import Layout from "../components/Layout";
import ContactForm from "./ContactForm";

const ContactPage = () => {
  return (
    <Layout>
      <ContactForm />
    </Layout>
  );
};

export default ContactPage;
