import { Skeleton } from "@/components/ui/skeleton.tsx";

function CompanyListItemSkeleton() {
  return (
    <div className="relative">
      <div
        className={`relative -ml-1.5 flex min-h-[92px] rounded-lg bg-white p-3`}
      >
        <div className="flex w-full items-center gap-3">
          <Skeleton className="h-[70px] w-[70px] flex-shrink-0 rounded" />
          <div className="flex min-w-[0] flex-1 flex-col gap-2">
            <div className="relative overflow-hidden">
              <Skeleton className="h-4 w-[120px]" />
            </div>
            <div className="flex items-center gap-4">
              <Skeleton className="h-4 w-[30px]" />
              <Skeleton className="h-4 w-[30px]" />
            </div>
          </div>
          <div className="mr-1 self-start">
            <Skeleton className="h-4 w-[50px]" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyListItemSkeleton;
