import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";

const analytics = Analytics({
  app: "webmetic",
  plugins: [
    googleTagManager({
      containerId: "GTM-KBG3C6WF",
    }),
  ],
});

export default analytics;
