import MsPartner from "../../assets/images/partners/microsoft-startup-badge-light.png";
import NvidiaPartner from "../../assets/images/partners/nvidia-inception.svg";
import ShPartner from "../../assets/images/partners/sh.png";
const Partners = () => {
  return (
    <section className="bg-gray-100 shadow-inner dark:bg-gray-600">
      <div className="mx-auto max-w-screen-xl px-4 py-4 lg:py-8">
        <h2 className="mb-8 text-left text-xl leading-tight tracking-tight text-gray-900 dark:text-white md:text-xl lg:mb-8">
          Unsere Partner und Acceleratoren:
        </h2>
        <div
          className="grid grid-cols-1 gap-8 text-gray-500 dark:text-gray-400 sm:gap-12 md:grid-cols-2 lg:grid-cols-3"
          fill="currentColor"
        >
          <div className="flex items-center justify-center">
            <img className="h-28" src={MsPartner}></img>
          </div>
          <div className="flex items-center justify-center">
            <img className="h-28" src={NvidiaPartner}></img>
          </div>
          <div className="flex items-center justify-center">
            <img className="h-28" src={ShPartner}></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
