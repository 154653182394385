import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Drawer,
  Typography,
  IconButton,
  Tooltip,
  Input,
  Button,
} from "@material-tailwind/react";

function WebsitesDrawer({ open, onClose }) {
  const [websites, setWebsites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newWebsite, setNewWebsite] = useState("");
  const [message, setMessage] = useState({ text: "", type: "" });

  useEffect(() => {
    if (open) {
      fetchWebsites();
    }
  }, [open]);

  const fetchWebsites = async () => {
    setIsLoading(true);
    setMessage({ text: "", type: "" });
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API}/api/account/websites_list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setWebsites(response.data);
    } catch (err) {
      console.error("Error fetching websites:", err);
      setMessage({
        text: "Websites konnten nicht geladen werden.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addWebsite = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage({ text: "", type: "" });
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API}/api/account/website_add`,
        { url: newWebsite },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      if (response.data && response.data.new_token) {
        localStorage.setItem("token", response.data.new_token);
      }
      setNewWebsite("");
      fetchWebsites();
      setMessage({
        text: "Website wurde hinzugefügt.",
        type: "success",
      });
    } catch (err) {
      console.error("Error adding website:", err);
      if (err.response && err.response.status === 400) {
        setMessage({
          text: "Diese Website ist bereits hinzugefügt.",
          type: "error",
        });
      } else {
        setMessage({
          text: "Website konnte nicht hinzugefügt werden.",
          type: "error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deleteWebsite = async (url) => {
    setIsLoading(true);
    setMessage({ text: "", type: "" });
    try {
      const response = await axios.delete(
        `${import.meta.env.VITE_APP_API}/api/account/website_remove`,
        {
          data: { url },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );

      if (response.data && response.data.new_token) {
        localStorage.setItem("token", response.data.new_token);
      }
      fetchWebsites();
      setMessage({ text: "Website wurde gelöscht.", type: "success" });
    } catch (err) {
      console.error("Error deleting website:", err);
      setMessage({
        text: "Website konnte nicht gelöscht werden.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="right"
      className="h-full overflow-y-auto border-l drop-shadow-lg sm:w-full md:w-2/3 lg:w-1/2 2xl:w-1/3"
      size={10000}
      overlay={true}
      overlayProps={{
        className: "fixed inset-0 bg-black bg-opacity-60",
      }}
    >
      <div className="-mt-[1px] flex items-center justify-between border-b px-4 py-3">
        <Typography variant="h4" color="blue-gray">
          Websites
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <div className="p-4">
        <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
          Website-Verwaltung
        </h2>
        <p className="mb-6 text-gray-500 dark:text-gray-400">
          Hier können Sie Ihre Websites verwalten, hinzufügen und entfernen.
        </p>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
          <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-3">
                URL
              </th>
              <th scope="col" className="px-4 py-3">
                Optionen
              </th>
            </tr>
          </thead>
          <tbody>
            {websites.map((website, index) => (
              <tr
                key={index}
                className={`hover:bg-gray-100 dark:hover:bg-gray-700 ${
                  index === websites.length - 1
                    ? ""
                    : "border-b dark:border-gray-600"
                }`}
              >
                <td className="whitespace-nowrap px-4 py-3 font-medium text-gray-900 dark:text-white">
                  <div className="flex items-center space-x-1.5">
                    <a
                      href={`https://${website.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className=""
                    >
                      {website.url}
                    </a>
                  </div>
                </td>
                <td className="whitespace-nowrap px-4 py-3 font-medium text-gray-900 dark:text-white">
                  <Tooltip content="Website löschen">
                    <button
                      onClick={() => deleteWebsite(website.url)}
                      className="inline-flex items-center rounded-lg p-1.5 text-center text-sm font-medium text-gray-500 hover:bg-gray-200 hover:text-gray-800 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-gray-100"
                    >
                      <svg
                        className="h-6 w-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                        />
                      </svg>
                    </button>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-6 p-4">
        <h3 className="mb-4 text-xl font-semibold dark:text-white">
          Neue Website hinzufügen
        </h3>
        <form onSubmit={addWebsite} className="space-y-4">
          <div>
            <label
              htmlFor="website"
              className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Website URL
            </label>
            <input
              type="text"
              id="website"
              value={newWebsite}
              onChange={(e) => setNewWebsite(e.target.value)}
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:shadow-sm-light dark:focus:border-primary-500 dark:focus:ring-primary-500"
              placeholder="beispiel.de"
              required
            />
            <p>
              Bitte nur die URL angeben.
              <br />
              Beispiel: domain.de
            </p>
          </div>
          <button className="btn w-full" type="submit" disabled={isLoading}>
            {isLoading ? "Website wird hinzugefügt..." : "Hinzufügen"}
          </button>
        </form>
      </div>
      {message.text && (
        <div
          className={`m-4 rounded-lg p-4 ${
            message.type === "error"
              ? "bg-red-100 text-red-700"
              : "bg-green-100 text-green-700"
          }`}
        >
          {message.text}
        </div>
      )}
    </Drawer>
  );
}

export default WebsitesDrawer;
