import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Drawer,
  Typography,
  IconButton,
  Button,
  Checkbox,
} from "@material-tailwind/react";

function AVContractDrawer({ open, onClose }) {
  const [contractData, setContractData] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  useEffect(() => {
    if (open) {
      fetchContractData();
      fetchCompanyData();
    }
  }, [open]);

  const fetchContractData = async () => {
    setIsLoading(true);
    setMessage({ type: "", text: "" });
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API}/api/account/av_contract`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setContractData(response.data);
    } catch (err) {
      console.error("Error fetching AV contract data:", err);
      setMessage({
        type: "error",
        text: "AV-Vertragsdaten konnten nicht geladen werden. Bitte versuchen Sie es erneut.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCompanyData = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API}/api/account/company_data`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setCompanyData(response.data);
    } catch (err) {
      console.error("Error fetching company data:", err);
      setMessage({
        type: "error",
        text: "Unternehmensdaten konnten nicht geladen werden. Bitte versuchen Sie es erneut.",
      });
    }
  };

  const createAVContract = async () => {
    if (!agreeToTerms) {
      setMessage({
        type: "error",
        text: "Bitte stimmen Sie den Bedingungen zu, bevor Sie den Vertrag erstellen.",
      });
      return;
    }
    setIsLoading(true);
    setMessage({ type: "", text: "" });
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API}/api/account/av_contract_create`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Webmetic_AV_Vertrag.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setMessage({
        type: "success",
        text: "AV-Vertrag wurde erfolgreich erstellt und heruntergeladen.",
      });
      fetchContractData();
    } catch (err) {
      console.error("Error creating AV contract:", err);
      setMessage({
        type: "error",
        text: "AV-Vertrag konnte nicht erstellt werden. Bitte versuchen Sie es erneut.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const downloadAVContract = async () => {
    setIsLoading(true);
    setMessage({ type: "", text: "" });
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API}/api/account/av_contract_download`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Webmetic_AV_Vertrag.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setMessage({
        type: "success",
        text: "AV-Vertrag wurde erfolgreich heruntergeladen.",
      });
    } catch (err) {
      console.error("Error downloading AV contract:", err);
      setMessage({
        type: "error",
        text: "AV-Vertrag konnte nicht heruntergeladen werden. Bitte versuchen Sie es erneut.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isCompanyDataComplete = () => {
    return (
      companyData &&
      companyData.company_name &&
      companyData.company_data &&
      companyData.company_data.street &&
      companyData.company_data.postal_code &&
      companyData.company_data.city
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="right"
      className="h-full overflow-y-auto border-l drop-shadow-lg sm:w-full md:w-2/3 lg:w-1/2 2xl:w-1/3"
      size={10000}
      overlay={true}
      overlayProps={{
        className: "fixed inset-0 bg-black bg-opacity-60",
      }}
    >
      <div className="-mt-[1px] flex items-center justify-between border-b px-4 py-3">
        <Typography variant="h4" color="blue-gray">
          AV-Vertrag
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <div className="p-4">
        <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
          Auftragsverarbeitungs (AV)-Vertrag
        </h2>
        <p className="mb-6 text-gray-500 dark:text-gray-400">
          Erstellen oder laden Sie hier Ihren AV-Vertrag für Ihre Unterlagen
          herunter.
        </p>

        {!isCompanyDataComplete() && (
          <div className="mb-4 rounded-lg bg-yellow-100 p-4 text-yellow-700">
            Bitte fügen Sie die fehlenden Informationen in Ihrem{" "}
            <a
              href="?menu=company_profile"
              className="font-semibold text-gray-900 underline decoration-blue-500 dark:text-white"
            >
              Firmenprofil
            </a>{" "}
            hinzu.
          </div>
        )}

        {isLoading ? (
          <p>Wird geladen...</p>
        ) : contractData && contractData.has_contract ? (
          <div>
            <p>
              Ihr AV-Vertrag wurde erstellt am:{" "}
              {new Date(contractData.created_at).toLocaleString("de-DE", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
            <button onClick={downloadAVContract} className="btn mt-4">
              AV-Vertrag herunterladen
            </button>
          </div>
        ) : (
          <div>
            <p>Sie haben noch keinen AV-Vertrag.</p>
            {isCompanyDataComplete() && (
              <>
                <div className="mt-4 flex items-center">
                  <Checkbox
                    id="agree-terms"
                    checked={agreeToTerms}
                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                  />
                  <label htmlFor="agree-terms" className="ml-2">
                    Ich bestätige die Vereinbarung.
                  </label>
                </div>
                <button
                  onClick={createAVContract}
                  className="btn mt-4"
                  disabled={!agreeToTerms}
                >
                  AV-Vertrag erstellen
                </button>
              </>
            )}
          </div>
        )}

        <div className="mt-6 rounded-lg bg-blue-100 p-4 text-blue-700">
          Eine separate Unterschrift ist nicht erforderlich, da Ihr
          Einverständnis bereits mit der Bestätigung der Checkbox vorliegt. Der
          AV-Vertrag muss nicht zurückgesendet werden.
        </div>

        {message.text && (
          <div
            className={`mt-4 rounded-lg p-4 ${
              message.type === "error"
                ? "bg-red-100 text-red-700"
                : "bg-green-100 text-green-700"
            }`}
          >
            {message.text}
          </div>
        )}
      </div>
    </Drawer>
  );
}

export default AVContractDrawer;
