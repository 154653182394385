export const apiPaths = {
  auth: {
    login: () => "/api/auth/user_login",
  },
  company: {
    search: () => "/api/search_companies",
    list: () => "/api/companies_list",
  },
  segment: {
    createSegment: () => "/api/segments/segment_create",
    getSegments: () => "/api/segments/segments_list",
    getSegmentById: (segment_id: string) =>
      `/api/segments/segment_get/${segment_id}`,
    updateSegment: (segment_id: string) =>
      `/api/segments/segment_update/${segment_id}`,
    deleteSegment: (segment_id: string) =>
      `/api/segments/segment_delete/${segment_id}`,
  },
} as const;
