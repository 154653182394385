import { DateTime } from "luxon";
import clsx from "clsx";
import { Tooltip } from "flowbite-react";

function getScoreLevel(percentage: number) {
  if (percentage < 0 || percentage > 100) {
    throw new Error("Percentage must be between 0 and 100.");
  }

  if (percentage <= 33) {
    return "low";
  } else if (percentage <= 66) {
    return "medium";
  } else {
    return "high";
  }
}
// Define the type for CompaniesDetails
interface CompaniesDetails {
  sessions?: Session[];
  lead_score: number;
}
// Define the type for userData
interface UserData {
  user_events_count?: number;
}

// Define the type for Session
interface Session {
  user_data?: UserData[];
  timestamp?: string;
}

function LeadRating({
  companiesDetails,
}: {
  companiesDetails: CompaniesDetails;
}) {
  const sessions = companiesDetails.sessions || [];

  // Calculate the number of sessions
  const visits = sessions.length;

  // Calculate the total number of pages visited
  const pagesVisited = sessions.reduce(
    (total: number, session: Session) =>
      total + (session.user_data?.length || 0),
    0,
  );

  // Calculate the total number of interactions
  const interactions = sessions.reduce((total: number, session: Session) => {
    return (
      total +
      (session.user_data || []).reduce(
        (sessionTotal: number, userData: UserData) =>
          sessionTotal + (userData.user_events_count || 0),
        0,
      )
    );
  }, 0);

  // Get the timestamp of the last activity
  const lastActivityTimestamp = sessions[sessions.length - 1]?.timestamp || "";

  // Function to get German relative time
  const getGermanRelativeTime = (timestamp: string) => {
    if (!timestamp) return "N/A";

    return DateTime.fromISO(timestamp)
      .setLocale("de")
      .toRelative({ base: DateTime.now() });
  };

  return (
    <div className="flex items-center justify-between gap-2 rounded-md border border-gray-200 p-4">
      <div className="flex flex-1 items-center gap-2">
        <div className="w-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 48 48"
            version="1.1"
            viewBox="0 0 48 48"
            xmlSpace="preserve"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="3"
              d="M29 28.137V9.5a5 5 0 00-10 0v18.637A8.48 8.48 0 0015.5 35a8.5 8.5 0 0017 0 8.48 8.48 0 00-3.5-6.863z"
            ></path>
            <circle cx="24" cy="35" r="5"></circle>
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="3"
              d="M24 33.5L24 17.5"
            ></path>
          </svg>
        </div>
        <div>
          <div className="flex items-center gap-1">
            <p className="text-sm">Lead-Score:</p>
            <Tooltip
              content="Der Lead-Score hängt von der Sitzungsdauer und der Anzahl der Besuche (Session) ab."
              trigger="hover"
              animation="duration-300"
              style="light"
            >
              <svg
                className="h-4 w-4 text-gray-800"
                aria-hidden="true"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </Tooltip>
          </div>
          <span
            className={clsx("min-h-[16px] text-sm", {
              "text-green-500":
                getScoreLevel(companiesDetails.lead_score) === "high",
              "text-orange-500":
                getScoreLevel(companiesDetails.lead_score) === "medium",
              "text-red-500":
                getScoreLevel(companiesDetails.lead_score) === "low",
            })}
          >
            {companiesDetails.lead_score}%
          </span>
        </div>
      </div>
      <div className="flex-1">
        <div className="gap-1">
          <p className="text-sm">Besuche:</p>
        </div>
        <span className="text-sm">{visits}</span>
      </div>
      <div className="flex-1">
        <div className="gap-1">
          <p className="text-sm">Unterseiten:</p>
        </div>
        <span className="text-sm">{pagesVisited}</span>
      </div>
      <div className="flex-1">
        <div className="gap-1">
          <p className="text-sm">Interaktionen:</p>
        </div>
        <span className="text-sm">{interactions}</span>
      </div>
      <div className="flex-1">
        <div className="gap-1">
          <p className="text-sm">Letzte Aktivität:</p>
        </div>
        <span className="text-sm">
          {getGermanRelativeTime(lastActivityTimestamp)}
        </span>
      </div>
    </div>
  );
}

export default LeadRating;
