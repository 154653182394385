import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { companiesDetailsQuery } from "./companies.loader.ts";
import CompaniesContent from "./company-content.tsx";
import LeadRating from "@/routes/companies/company-details/lead-ratings.tsx";
import CompanySessions from "@/routes/companies/company-details/company-sessions.tsx";
import { useEffect, useRef } from "react";

function CompanyDetails() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { data: companiesDetails } = useQuery(
    companiesDetailsQuery(searchParams, params),
  );

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [params, searchParams]);

  if (!companiesDetails) return null;
  return (
    <div ref={containerRef} className="h-[calc(100dvh-4rem)] overflow-scroll">
      <div className="flex h-full w-full flex-col gap-4 border-l bg-white px-4 py-2">
        <CompaniesContent companiesDetails={companiesDetails} />
        <LeadRating companiesDetails={companiesDetails} />
        <CompanySessions companiesDetails={companiesDetails} />
      </div>
    </div>
  );
}

export default CompanyDetails;
