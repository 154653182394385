import { Link } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { Flowbite, Navbar, DarkThemeToggle } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../provider/authProvider";
import RegisterModal from "../modal/RegisterModal";
import IconLight from "../../assets/images/webmetic_icon_light.png";
import IconDark from "../../assets/images/webmetic_icon_dark.png";
import LogoLight from "../../assets/images/webmetic_logo_light.png";
import LogoDark from "../../assets/images/webmetic_logo_dark.png";
import analytics from "../../analytics";

const customTheme = {
  navbar: {
    link: {
      base: "lg:hover:text-primary-700 block border-b border-gray-100 md:border-0 py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-50 hover:text-webmetic_dark dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:dark:hover:bg-transparent lg:dark:hover:text-white",
      active: {
        on: "text-primary-700",
        off: "text-primary-700",
      },
    },
    collapse: {
      base: "w-full md:block md:w-auto",
      list: "mt-4 flex flex-col md:mt-0 md:flex-row lg:space-x-8 md:text-sm md:font-medium",
      hidden: {
        on: "hidden",
        off: "",
      },
    },
  },
};

const Header = () => {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const [darkMode, setDarkMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const updateFavicon = useCallback(() => {
    const themeMode = localStorage.getItem("flowbite-theme-mode");
    const favicon = document.getElementById("site-icon");
    if (favicon) {
      favicon.href =
        themeMode === "light"
          ? "../../src/assets/images/webmetic_icon_dark.png"
          : "../../src/assets/images/webmetic_icon_light.png";
    }
  }, []);
  const handleClick = useCallback((source) => {
    analytics.track("register_modal_opened", {
      source: source,
    });
    setIsModalOpen(true);
  }, []);
  /*
  useEffect(() => {
    // Initial update
    updateFavicon();

    // Add click event listener to the dark theme toggle button
    const toggleButton = document.querySelector(
      '[data-testid="dark-theme-toggle"]',
    );
    if (toggleButton) {
      toggleButton.addEventListener("click", updateFavicon);
    }

    // Cleanup
    return () => {
      if (toggleButton) {
        toggleButton.removeEventListener("click", updateFavicon);
      }
    };
  }, [updateFavicon]);
*/
  return (
    <section>
      <div className="border-gray-200 bg-white px-2 drop-shadow-sm dark:border-0 dark:bg-gray-800 sm:px-4">
        <Flowbite theme={{ theme: customTheme }}>
          <Navbar fluid rounded className="mx-auto max-w-screen-xl lg:px-6">
            <Navbar.Brand href="/">
              <img
                src={LogoLight}
                className="mr-3 mt-1 h-7 dark:hidden sm:h-9"
                alt="Webmetic Logo"
              />
              <img
                src={LogoDark}
                className="mr-3 mt-1 hidden h-7 dark:block sm:h-9"
                alt="Webmetic Logo"
              />
            </Navbar.Brand>
            <div className="flex md:order-2">
              <div className="mr-2 hidden sm:block">
                <DarkThemeToggle />
              </div>
              <Link
                to={!isAuthenticated ? "/login" : "/dashboard"}
                className="btn-alt mr-2 hidden sm:block"
              >
                {!isAuthenticated ? t("Login") : "Dashboard"}
              </Link>
              <button
                onClick={() => handleClick("header_button")}
                className="btn font mr-2 hidden sm:block"
              >
                Kostenlos Anmelden
              </button>
              <div className="md:hidden">
                <Navbar.Toggle />
              </div>
            </div>
            <Navbar.Collapse>
              <Navbar.Link href="/">Start</Navbar.Link>
              <Navbar.Link href="/preise">Preise</Navbar.Link>
              <Navbar.Link href="/funktionen">Funktionen</Navbar.Link>
              <Navbar.Link href="/datenschutz">Datenschutz</Navbar.Link>
              <Navbar.Link href="/kontakt">Kontakt</Navbar.Link>
              <Navbar.Link className="sm:hidden" href="/login">
                Login
              </Navbar.Link>
              <Navbar.Link
                className="sm:hidden"
                onClick={() => handleClick("header_mobile_button")}
              >
                Kostenlos anmelden
              </Navbar.Link>
            </Navbar.Collapse>
          </Navbar>
        </Flowbite>
      </div>
      <div className="flex items-center gap-2"></div>
      <RegisterModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        initialWebsite=""
      />
    </section>
  );
};

export default Header;
