import { deleteData, getData, postData, putData } from "./crudHandlers";

import {
  AxiosResponseSuccess,
  CompanyListResponseData,
  CreateSegmentResponse,
  ListCompanyParams,
  LoginRequestData,
  SearchCompanyParams,
  SearchCompanyResponseData,
  UpdateSegmentProps,
  apiPaths,
} from ".";
import { CreateSegmentData, Segment } from "@/types/segment";

export const apiHandlers = {
  auth: {
    login: (data: LoginRequestData): AxiosResponseSuccess<unknown> => {
      return postData<unknown>(apiPaths.auth.login(), { data });
    },
  },
  company: {
    search: (
      params: SearchCompanyParams,
    ): AxiosResponseSuccess<SearchCompanyResponseData> => {
      return getData<SearchCompanyResponseData>(apiPaths.company.search(), {
        params,
      });
    },
    list: (
      params: ListCompanyParams,
    ): AxiosResponseSuccess<CompanyListResponseData> => {
      return getData<CompanyListResponseData>(apiPaths.company.list(), {
        params,
      });
    },
  },
  segment: {
    getSegments: (): AxiosResponseSuccess<Segment[]> => {
      return getData(apiPaths.segment.getSegments());
    },
    getSegmentById: (segment_id: string): AxiosResponseSuccess<Segment> => {
      return getData(apiPaths.segment.getSegmentById(segment_id));
    },
    createSegment: (
      data: CreateSegmentData,
    ): AxiosResponseSuccess<CreateSegmentResponse> => {
      return postData(apiPaths.segment.createSegment(), {
        data,
      });
    },
    updateSegment: ({
      id,
      data,
    }: UpdateSegmentProps): AxiosResponseSuccess<CreateSegmentData> => {
      return putData(apiPaths.segment.updateSegment(id), {
        data,
      });
    },
    deleteSegment: (id: string): AxiosResponseSuccess<string> => {
      return deleteData(apiPaths.segment.deleteSegment(id));
    },
  },
} as const;
