import React, { useState, useCallback } from "react";
import RegisterModal from "../modal/RegisterModal"; // Ensure this path is correct
import analytics from "../../analytics";

export const CTA_1 = () => {
  const [website, setWebsite] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const trackRegisterModalOpen = useCallback(
    (source) => {
      analytics.track("register_modal_opened", {
        source: source,
        website: website,
      });
    },
    [website],
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      trackRegisterModalOpen("cta_1_button");
      setIsModalOpen(true);
    },
    [website, trackRegisterModalOpen],
  );

  const handleInputChange = useCallback((event) => {
    setWebsite(event.target.value);
  }, []);

  return (
    <section className="border-gray-100 bg-gray-50 drop-shadow-xl dark:border-gray-700 dark:bg-gray-800">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-md text-center">
          <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            Starten Sie Ihre kostenlose Testphase
          </h2>
          <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
            Identifizieren und analysieren Sie Website-Besucher mit Webmetic.
          </p>
          <form
            className="mx-auto max-w-screen-sm lg:px-20"
            onSubmit={handleSubmit}
          >
            <div className="mb-3 flex items-center">
              <div className="relative mr-3 w-full">
                <label
                  htmlFor="website-input"
                  className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Bitte Website eingeben
                </label>
                <div className="relative">
                  <input
                    type="text"
                    id="website-input"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-4 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                    value={website}
                    onChange={handleInputChange}
                    placeholder="Website eingeben"
                  />
                  <button
                    type="submit"
                    className="btn absolute bottom-2.5 right-0.5 -mb-1 mr-1 inline-flex items-center text-white"
                  >
                    Kostenlos testen
                    <svg
                      className="-mr-2 ml-1 h-4 w-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m10 16 4-4-4-4"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="text-center text-sm font-medium text-gray-500 dark:text-gray-300">
              Probieren Sie Webmetic 14 Tage unverbindlich aus.
            </div>
          </form>
        </div>
      </div>
      <RegisterModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        initialWebsite={website}
      />
    </section>
  );
};
export const CTA_2 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const trackRegisterModalOpen = useCallback((source) => {
    analytics.track("register_modal_opened", {
      source: source,
    });
  }, []);

  const handleClick = useCallback(() => {
    trackRegisterModalOpen("cta_2_button");
    setIsModalOpen(true);
  }, [trackRegisterModalOpen]);

  return (
    <section className="border-b border-t border-gray-100 bg-gray-50 dark:border-gray-700 dark:bg-gray-800">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h2 className="mb-4 text-4xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">
            Jetzt starten und Leads generieren
          </h2>
          <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
            Nutzen Sie Webmetic 14 Tage lang kostenlos.
          </p>
          <button
            onClick={handleClick}
            className="btn bg-primary-70 text-white"
          >
            Jetzt starten
          </button>
        </div>
      </div>
      <RegisterModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        initialWebsite=""
      />
    </section>
  );
};

export const CTA_3 = () => {
  const handleAppointmentClick = useCallback(() => {
    analytics.track("appointment_button_clicked", {
      source: "cta_3",
    });
    window.open(
      "https://outlook.office365.com/book/DMEXCOTerminvereinbaren@webmetic.de/",
      "_blank",
      "noopener,noreferrer",
    );
  }, []);

  return (
    <section className="border-b border-t border-gray-100 bg-gray-100 dark:border-gray-700 dark:bg-gray-800">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-lg text-center">
          <h2 className="mb-4 text-4xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">
            Persönlich auf der DMEXCO oder online?
          </h2>
          <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
            Sichern Sie sich jetzt Ihren Termin - vor Ort in Köln oder bequem
            von Ihrem Büro aus.
          </p>
          <button
            onClick={handleAppointmentClick}
            className="btn bg-primary-70 text-white"
          >
            Jetzt Termin vereinbaren
          </button>
        </div>
      </div>
    </section>
  );
};
