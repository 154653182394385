import { LoaderFunctionArgs, Params } from "react-router-dom";
import { QueryClient, queryOptions } from "@tanstack/react-query";

export async function getCompaniesDetails(
  searchParams: URLSearchParams,
  params: Readonly<Params<string>>,
) {
  if (!params.company_details_id) throw new Error();
  const filter = new URLSearchParams(searchParams);
  filter.set("company_id", params.company_details_id);
  const res = await fetch(
    `${import.meta.env.VITE_APP_API}/api/company_details?${filter}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  );
  return (await res.json()) as CompaniesDetails;
}

export const companiesDetailsQueryKey = (
  searchParams: URLSearchParams,
  params: Readonly<Params<string>>,
) => ["api", "company_details", searchParams, params];

export interface CompaniesDetailsSessionUserData {
  document_location: string;
  document_title: string;
  referrer: string;
  scroll_depth: number;
  time_spent: number;
  timestamp: string;
  user_events_count: number;
}

export interface CompaniesDetailsSession {
  aid: string;
  client_id: string;
  color_depth: string;
  color_scheme: string;
  connection_type: string;
  cookies_enabled: boolean;
  gci: string;
  gsi: string;
  gsn: string;
  platform: string;
  screen_resolution: string;
  session_duration: number;
  session_id: string;
  session_time: number;
  timestamp: string;
  user_agent: string;
  user_data: CompaniesDetailsSessionUserData[];
  user_language: string;
  utm_medium: string;
  utm_source: string;
  viewport: string;
  weburl: string;
  user_agent_category: string;
  user_agent_os: {
    family: string;
    version: string;
  };
}

export interface CompaniesDetails {
  address: string;
  city: string;
  company_id: string;
  company_logo_url: string;
  company_name: string;
  company_url: string;
  country: string;
  directors: string[];
  email_address: string;
  fax_number: string;
  full_description_de: string;
  full_description_en: string;
  lead_score: number;
  linkedin: string;
  // nace_codes: (2) [{…}, {…}]
  phone_number: string;
  postal_code: string;
  registration_court: string;
  registration_number: string;
  sessions: CompaniesDetailsSession[];
  short_description_de: string;
  short_description_en: string;
  vat_id: string;
}

export const companiesDetailsQuery = (
  searchParams: URLSearchParams,
  params: Readonly<Params<string>>,
) =>
  queryOptions({
    queryKey: companiesDetailsQueryKey(searchParams, params),
    queryFn: () => getCompaniesDetails(searchParams, params),
  });

function companiesDetailsLoader(queryClient: QueryClient) {
  return async ({ request, params }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    await queryClient.ensureQueryData(
      companiesDetailsQuery(url.searchParams, params),
    );
    return null;
  };
}

export default companiesDetailsLoader;
