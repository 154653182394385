import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "@/hooks/useUser.ts";
function Dashboard() {
  const user = useUser()!;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const menuParam = searchParams.get("menu");

    if (user.website && user.website.length > 0) {
      const targetPath = `/dashboard/${user.website[0]}/companies`;

      if (menuParam) {
        // If there's a menu parameter, redirect to the companies page with the menu parameter
        navigate(`${targetPath}?menu=${menuParam}`, { replace: true });
      } else if (location.pathname === "/dashboard") {
        // If we're at the root dashboard path without a menu parameter, just redirect to the companies page
        navigate(targetPath, { replace: true });
      }
    }
  }, [user, navigate, location]);

  if (!user.website || user.website.length === 0) {
    return <div>No website associated with this user.</div>;
  }

  // This component will not render anything as it will always redirect
  return null;
}

export default Dashboard;
