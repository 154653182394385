import React, { useState, useEffect } from "react";
import axios from "axios";
import { Drawer, Typography, IconButton } from "@material-tailwind/react";

function SettingsDrawer({ open, onClose }) {
  const [companyData, setCompanyData] = useState({
    company_name: "",
    company_data: {
      street: "",
      additional_info: "",
      postal_code: "",
      city: "",
      country: "",
      phone_number: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState({ type: "", text: "" });

  useEffect(() => {
    if (open) {
      fetchCompanyData();
    }
  }, [open]);

  const fetchCompanyData = async () => {
    setIsLoading(true);
    setMessage({ type: "", text: "" });
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API}/api/account/company_data`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setCompanyData(response.data);
    } catch (err) {
      console.error("Error fetching company data:", err);
      setMessage({
        type: "error",
        text: "Failed to fetch company data. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateCompanyData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage({ type: "", text: "" });
    try {
      const response = await axios.patch(
        `${import.meta.env.VITE_APP_API}/api/account/company_data_update`,
        companyData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      if (response.data.message === "Company profile updated successfully") {
        setMessage({
          type: "success",
          text: "Firmenprofil wurde erfolgreich aktualisiert.",
        });
      }
    } catch (err) {
      console.error("Error updating company data:", err);
      setMessage({
        type: "error",
        text: "Fehler beim Aktualisieren des Firmenprofils. Bitte versuchen Sie es erneut.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "company_name") {
      setCompanyData({ ...companyData, [name]: value });
    } else {
      setCompanyData({
        ...companyData,
        company_data: { ...companyData.company_data, [name]: value },
      });
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="right"
      className="h-full overflow-y-auto border-l drop-shadow-lg sm:w-full md:w-2/3 lg:w-1/2 2xl:w-1/3"
      size={10000}
      overlay={true}
      overlayProps={{
        className: "fixed inset-0 bg-black bg-opacity-60",
      }}
    >
      <div className="-mt-[1px] flex items-center justify-between border-b px-4 py-3">
        <Typography variant="h4" color="blue-gray">
          Firmenprofil
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <div className="p-4">
        <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
          Firmenprofil anpassen
        </h2>
        <p className="mb-6 text-gray-500 dark:text-gray-400">
          Verwalten Sie hier die aktuellen Informationen zu Ihrem Firmenprofil,
          wie Adresse, Kontaktinformationen und weitere Details.
        </p>

        <form onSubmit={updateCompanyData}>
          <div className="grid grid-cols-6 gap-2">
            <div className="col-span-6">
              <label
                htmlFor="company_name"
                className="mb-1 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Firmenname
              </label>
              <input
                type="text"
                name="company_name"
                id="company_name"
                value={companyData.company_name}
                onChange={handleInputChange}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6">
              <label
                htmlFor="street"
                className="mb-1 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Adresse
              </label>
              <input
                type="text"
                name="street"
                id="street"
                value={companyData.company_data.street}
                onChange={handleInputChange}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6">
              <label
                htmlFor="additional_info"
                className="mb-1 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Zusatz
              </label>
              <input
                type="text"
                name="additional_info"
                id="additional_info"
                value={companyData.company_data.additional_info}
                onChange={handleInputChange}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="postal_code"
                className="mb-1 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Postleitzahl
              </label>
              <input
                type="text"
                name="postal_code"
                id="postal_code"
                value={companyData.company_data.postal_code}
                onChange={handleInputChange}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="city"
                className="mb-1 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Stadt
              </label>
              <input
                type="text"
                name="city"
                id="city"
                value={companyData.company_data.city}
                onChange={handleInputChange}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6">
              <label
                htmlFor="country"
                className="mb-1 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Land
              </label>
              <input
                type="text"
                name="country"
                id="country"
                value={companyData.company_data.country}
                onChange={handleInputChange}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6">
              <label
                htmlFor="phone_number"
                className="mb-1 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Rufnummer
              </label>
              <input
                type="tel"
                name="phone_number"
                id="phone_number"
                value={companyData.company_data.phone_number}
                onChange={handleInputChange}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6">
              <button
                className="btn mt-2 w-full"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Wird gespeichert..." : "Speichern"}
              </button>
            </div>
          </div>
        </form>
      </div>
      {message.text && (
        <div
          className={`m-4 rounded-lg p-4 ${
            message.type === "error"
              ? "bg-red-100 text-red-700"
              : "bg-green-100 text-green-700"
          }`}
        >
          {message.text}
          {error && (
            <div className="m-4 rounded-lg bg-red-100 p-4 text-red-700">
              {error}
            </div>
          )}
        </div>
      )}
    </Drawer>
  );
}

export default SettingsDrawer;
