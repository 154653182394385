import React, { useState, useEffect } from "react";
import { Card, Label, RangeSlider } from "flowbite-react";

const ValueCalculator = ({ companies, price }) => {
  const [avgDealSize, setAvgDealSize] = useState(1000);
  const [conversionRate, setConversionRate] = useState(2);

  const [potentialLeads, setPotentialLeads] = useState(0);
  const [potentialRevenue, setPotentialRevenue] = useState(0);
  const [roas, setRoas] = useState(0);

  useEffect(() => {
    const leads = companies * (conversionRate / 100);
    const revenue = leads * avgDealSize;
    const calculatedRoas = (revenue / price) * 100;

    setPotentialLeads(leads);
    setPotentialRevenue(revenue);
    setRoas(calculatedRoas);
  }, [companies, avgDealSize, conversionRate, price]);

  return (
    <Card className="mt-8">
      <h2 className="mb-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        Value Rechner (ROAS / Werberentabilität)
      </h2>
      <div className="grid gap-6 md:grid-cols-2">
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="avgDealSize"
              value={`Durchschnittlicher Auftragswert: €${avgDealSize}`}
              className="text-lg font-semibold"
            />
          </div>
          <RangeSlider
            id="avgDealSize"
            min={100}
            max={20000}
            step={100}
            value={avgDealSize}
            onChange={(e) => setAvgDealSize(Number(e.target.value))}
            className="mb-4"
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="conversionRate"
              value={`Aktuelle Konversionsrate: ${conversionRate}%`}
              className="text-lg font-semibold"
            />
          </div>
          <RangeSlider
            id="conversionRate"
            min={0.1}
            max={10}
            step={0.1}
            value={conversionRate}
            onChange={(e) => setConversionRate(Number(e.target.value))}
            className="mb-4"
          />
        </div>
      </div>
      <div className="mt-6 grid gap-6 md:grid-cols-3">
        <div>
          <p className="text-lg font-semibold text-gray-700 dark:text-gray-400">
            Potenzielle identifizierte Unternehmen
          </p>
          <p className="text-3xl font-bold text-webmetic_dark dark:text-webmetic_light">
            {companies}
          </p>
        </div>
        <div>
          <p className="text-lg font-semibold text-gray-700 dark:text-gray-400">
            Potenzielle Leads
          </p>
          <p className="text-3xl font-bold text-webmetic_dark dark:text-webmetic_light">
            {potentialLeads.toFixed(1)}
          </p>
        </div>
        <div>
          <p className="text-lg font-semibold text-gray-700 dark:text-gray-400">
            Potenzieller Umsatz
          </p>
          <p className="text-3xl font-bold text-webmetic_dark dark:text-webmetic_light">
            €{potentialRevenue.toFixed(2)}
          </p>
        </div>
      </div>
      <div className="mt-6">
        <p className="text-xl font-semibold text-gray-700 dark:text-gray-400">
          Return on Ad Spend (ROAS) / Werberentabilität
        </p>
        <p className="text-4xl font-bold text-webmetic_dark dark:text-webmetic_light">
          {roas.toFixed(2)}%
        </p>
      </div>
      <div className="mt-6">
        <h6 className="text-lg font-semibold text-gray-700 dark:text-gray-400">
          ROAS-Berechnung:
        </h6>
        <p className="text-gray-600 dark:text-gray-300">
          Potenzielle Leads = {companies} * {conversionRate}% ={" "}
          {potentialLeads.toFixed(1)}
        </p>
        <p className="text-gray-600 dark:text-gray-300">
          Potenzieller Umsatz = {potentialLeads.toFixed(1)} * €{avgDealSize} = €
          {potentialRevenue.toFixed(2)}
        </p>
        <p className="text-gray-600 dark:text-gray-300">
          ROAS = (€{potentialRevenue.toFixed(2)} / €{price.toFixed(2)}) * 100 ={" "}
          {roas.toFixed(2)}%
        </p>
      </div>
    </Card>
  );
};

export default ValueCalculator;
