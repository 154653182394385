import dataAnalysis from "../../assets/images/data_analysis.svg";
import dataPreview from "../../assets/images/data_preview.svg";

const dataOverview = () => {
  return (
    <section className="border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-16 lg:px-6">
        <div className="mx-auto mb-8 max-w-screen-md text-center lg:mb-16">
          <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            Datengestützte Einblicke
          </h2>
          <p className="font-light text-gray-500 dark:text-gray-400 sm:text-xl">
            Tauchen Sie in eine umfassende Analyse von Interaktion und
            Unternehmensdetails ein. <br />
            Ebenso technische Spezifikationen bis hin zu Brancheneinordnungen
            werden transparent - entdecken Sie wertvolle Erkenntnisse, die Ihre
            Geschäftsstrategie auf ein neues Level heben.
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 md:gap-8 md:space-y-0 lg:grid-cols-3 xl:grid-cols-4 xl:gap-8">
          <div className="rounded bg-white p-6 shadow dark:bg-gray-800">
            <div className="mb-4 flex h-10 w-10 items-center justify-center rounded bg-webmetic_light dark:bg-webmetic_dark lg:h-12 lg:w-12">
              <svg
                className="h-5 w-5 text-webmetic_dark dark:text-webmetic_light lg:h-6 lg:w-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <h3 className="text-l mb-2 font-bold dark:text-white">
              Unternehmensprofil
            </h3>
            <ul className="max-w-md list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
              <li>Firmenname</li>
              <li>Adresse</li>
              <li>Geschäftsführung</li>
              <li>Kontaktdaten</li>
              <li>Unternehmenswebsite</li>
            </ul>
          </div>
          <div className="rounded bg-white p-6 shadow dark:bg-gray-800">
            <div className="mb-4 flex h-10 w-10 items-center justify-center rounded bg-webmetic_light dark:bg-webmetic_dark lg:h-12 lg:w-12">
              <svg
                className="h-5 w-5 text-webmetic_dark dark:text-webmetic_light lg:h-6 lg:w-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <h3 className="text-l mb-2 font-bold dark:text-white">
              Unternehmensdetails
            </h3>
            <ul className="max-w-md list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
              <li>Kurzbeschreibung Deutsch</li>
              <li>Kurzbeschreibung Englisch</li>
              <li>Beschreibung Deutsch</li>
              <li>Beschreibung Englisch</li>
              <li>Firmenlogo</li>
            </ul>
          </div>
          <div className="rounded bg-white p-6 shadow dark:bg-gray-800">
            <div className="mb-4 flex h-10 w-10 items-center justify-center rounded bg-webmetic_light dark:bg-webmetic_dark lg:h-12 lg:w-12">
              <svg
                className="h-5 w-5 text-webmetic_dark dark:text-webmetic_light lg:h-6 lg:w-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
                <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
              </svg>
            </div>
            <h3 className="text-l mb-2 font-bold dark:text-white">
              Brancheneinordnung
            </h3>
            <ul className="max-w-md list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
              <li>Branchencodes</li>
              <li>Beschreibungen</li>
              <li>Wirtschaftsbereich</li>
              <li>Branchengruppe</li>
              <li>Tätigkeitsbeschreibung</li>
            </ul>
          </div>
          <div className="rounded bg-white p-6 shadow dark:bg-gray-800">
            <div className="mb-4 flex h-10 w-10 items-center justify-center rounded bg-webmetic_light dark:bg-webmetic_dark lg:h-12 lg:w-12">
              <svg
                className="h-5 w-5 text-webmetic_dark dark:text-webmetic_light lg:h-6 lg:w-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <h3 className="text-l mb-2 font-bold dark:text-white">
              Geographische Informationen
            </h3>
            <ul className="max-w-md list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
              <li>Besucherstandort</li>
              <li>Benutzersprache</li>
              <li>Stadt</li>
              <li>Region/Bundesland</li>
              <li>Land</li>
            </ul>
          </div>
          <div className="rounded bg-white p-6 shadow dark:bg-gray-800">
            <div className="mb-4 flex h-10 w-10 items-center justify-center rounded bg-webmetic_light dark:bg-webmetic_dark lg:h-12 lg:w-12">
              <svg
                className="h-5 w-5 text-webmetic_dark dark:text-webmetic_light lg:h-6 lg:w-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <h3 className="text-l mb-2 font-bold dark:text-white">
              Session-Informationen
            </h3>
            <ul className="max-w-md list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
              <li>Interaktionszeitpunkt</li>
              <li>Interaktionszeit</li>
              <li>Gesamte Sessiondauer</li>
              <li>Besuchte Webseite</li>
              <li>Besuchte Unterseiten</li>
            </ul>
          </div>

          <div className="rounded bg-white p-6 shadow dark:bg-gray-800">
            <div className="mb-4 flex h-10 w-10 items-center justify-center rounded bg-webmetic_light dark:bg-webmetic_dark lg:h-12 lg:w-12">
              <svg
                className="h-5 w-5 text-webmetic_dark dark:text-webmetic_light lg:h-6 lg:w-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
              </svg>
            </div>
            <h3 className="text-l mb-2 font-bold dark:text-white">
              Seiteninteraktion
            </h3>
            <ul className="max-w-md list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
              <li>Aktuelle URL</li>
              <li>Seitentitel</li>
              <li>Scroll-Tiefe</li>
              <li>Interne Quelle</li>
              <li>Verweildauer</li>
            </ul>
          </div>
          <div className="rounded bg-white p-6 shadow dark:bg-gray-800">
            <div className="mb-4 flex h-10 w-10 items-center justify-center rounded bg-webmetic_light dark:bg-webmetic_dark lg:h-12 lg:w-12">
              <svg
                className="h-5 w-5 text-webmetic_dark dark:text-webmetic_light lg:h-6 lg:w-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <h3 className="text-l mb-2 font-bold dark:text-white">
              Technische Details
            </h3>
            <ul className="max-w-md list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
              <li>Browserinfo</li>
              <li>Betriebssystem</li>
              <li>Bildschirmauflösung</li>
              <li>Cookie-Status</li>
              <li>Verbindungstyp</li>
            </ul>
          </div>
          <div className="rounded bg-white p-6 shadow dark:bg-gray-800">
            <div className="mb-4 flex h-10 w-10 items-center justify-center rounded bg-webmetic_light dark:bg-webmetic_dark lg:h-12 lg:w-12">
              <svg
                className="h-5 w-5 text-webmetic_dark dark:text-webmetic_light lg:h-6 lg:w-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
              </svg>
            </div>
            <h3 className="text-l mb-2 font-bold dark:text-white">
              Traffic-Quellen
            </h3>
            <ul className="max-w-md list-inside list-disc space-y-1 text-gray-500 dark:text-gray-400">
              <li>Externe Quelle</li>
              <li>Traffic-Ursprung</li>
              <li>Marketing-Kanal</li>
              <li>Kampagnenname und ID</li>
              <li>Suchbegriffe</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default dataOverview;
