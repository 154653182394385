import dataAnalysis from "../../assets/images/data_analysis.svg";
import tagManagerGallery from "../../assets/images/google_tag_manager_template.png";

const easyImplementation = () => {
  return (
    <section className="border-bborder-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl items-center gap-8 px-4 py-8 sm:py-16 lg:grid lg:grid-cols-2 lg:px-6 xl:gap-16">
        <img
          className="mb-4 w-full rounded-lg drop-shadow-2xl lg:mb-0"
          src={tagManagerGallery}
          alt="feature image"
        />
        <div className="text-gray-500 dark:text-gray-400 sm:text-lg">
          <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            Einfachste Integration
          </h2>
          <p className="mb-8 font-light lg:text-xl">
            Dank zertifizierter Lösungen lässt sich Webmetic mit nur wenigen
            Klicks auf Ihrer Website integrieren.
          </p>
          <div className="mb-6 border-b border-t border-gray-200 py-8 dark:border-gray-700">
            <div className="flex">
              <div className="mr-4 flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="192"
                  height="192"
                  x="0"
                  y="0"
                  viewBox="0 0 192 192"
                  className="h-5 w-5 text-primary-600 dark:text-primary-300"
                  fill="currentColor"
                >
                  <path fill="none" d="M0 0H192V192H0z"></path>
                  <path
                    fill="#8AB4F8"
                    d="M111.31 176.79L80.76 147 146.37 80 178 111z"
                  ></path>
                  <path
                    fill="#4285F4"
                    d="M111.44 45.08L81 14 14.44 79.93c-8.58 8.58-8.58 22.49 0 31.08L80 177l31-29-49.95-52.53 50.39-50.39z"
                  ></path>
                  <path
                    fill="#8AB4F8"
                    d="M177.56 80.44l-66-66c-8.59-8.59-22.52-8.59-31.11 0s-8.59 22.52 0 31.11l66 66c8.59 8.59 22.52 8.59 31.11 0s8.59-22.52 0-31.11z"
                  ></path>
                  <circle cx="95.5" cy="162.5" r="21.5" fill="#246FDB"></circle>
                </svg>
              </div>
              <div>
                <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
                  Google Tag Manager Template
                </h3>
                <p className="mb-2 font-light text-gray-500 dark:text-gray-400">
                  Das Webmetic-Template können Sie jederzeit zu Ihrem Tag
                  Manager hinzufügen. Einfach Ihre Kundennummer eintragen und
                  die Identifizierung startet sofort.
                </p>
                <a
                  href="https://tagmanager.google.com/gallery/#/owners/yanniksuess/templates/webmetic-tag-manager"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-primary-600 hover:text-primary-800 dark:text-primary-300 dark:hover:text-primary-400"
                >
                  Template ansehen
                  <svg
                    className="ml-1 h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="flex pt-8">
              <div className="mr-4 flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-purple-100 dark:bg-purple-900">
                <svg
                  className="h-5 w-5 text-purple-600 dark:text-purple-300"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit="1.414"
                  clipRule="evenodd"
                  viewBox="0 0 64 60"
                >
                  <g>
                    <circle
                      cx="33.41"
                      cy="30.18"
                      r="12.56"
                      fill="#0095FF"
                    ></circle>
                    <circle
                      cx="59.17"
                      cy="4.83"
                      r="4.83"
                      fill="#B8CEE1"
                    ></circle>
                    <circle
                      cx="49.52"
                      cy="50.76"
                      r="8.7"
                      fill="#0D47A1"
                    ></circle>
                    <circle
                      cx="6.76"
                      cy="40.73"
                      r="6.76"
                      fill="#F25900"
                    ></circle>
                  </g>
                </svg>
              </div>
              <div>
                <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
                  Usercentrics DPS (Optional)
                </h3>
                <p className="mb-2 font-light text-gray-500 dark:text-gray-400">
                  Webmetic ist bereits als Data Processing Service (DPS) bei
                  Usercentrics hinterlegt. Fügen Sie Webmetic einfach zu Ihren
                  Usercentrics Services hinzu.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default easyImplementation;
