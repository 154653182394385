import { Link } from "react-router-dom";
const DataPrivacyHeadline = ({ showPrivacyButton = false }) => {
  return (
    <section className="bg-grey-50 drop-shadow dark:bg-gray-800">
      <div className="mx-auto flex max-w-screen-xl flex-wrap px-4 py-4 lg:py-8">
        <div className="w-full p-4 md:w-2/3">
          <blockquote className="relative py-4">
            <svg
              className="absolute -start-8 -top-6 size-16 text-gray-100 dark:text-neutral-700"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z"
                fill="currentColor"
              ></path>
            </svg>

            <div className="relative z-10">
              <p className="text-gray-800 dark:text-white sm:text-xl">
                <em>
                  Geprüfte Datenschutzkonformität: <br />
                  Webmetic ermöglicht ein datenschutzkonformes Webtracking Ihrer
                  Seitenbesucher.
                </em>
              </p>
            </div>
          </blockquote>
        </div>
        <div className="w-full p-4 md:w-1/3">
          <p className="text-neutral-700 dark:text-gray-100">
            Unsere Kanzlei für Datenschutz:
          </p>
          <svg xmlns="http://www.w3.org/2000/svg" width="94.842" height="48">
            <path
              d="M0 47.458h6.549V34.496h10.164v-6.233H6.549v-6.64h12.964V15.39H0Zm37.807-32.07h-10.66v32.07H33.7V36.979h4.11c6.5 0 11.247-4.7 11.247-10.8s-4.743-10.8-11.247-10.8m-.09 15.448H33.7v-9.3h4.02a4.646 4.646 0 0 1 4.969 4.652 4.646 4.646 0 0 1-4.969 4.652m16.351 6.685c.271 5.285 4.652 10.479 12.331 10.479 6.73 0 11.157-4.065 11.157-9.53 0-4.969-3.117-8.266-8.311-9.711l-3.749-1.039c-2.62-.768-4.065-1.852-4.02-3.839.045-1.9 1.852-2.981 4.155-2.981a4.73 4.73 0 0 1 4.743 3.7h6.5c-.226-4.788-4.336-9.756-11.247-9.756-6.414 0-10.8 3.568-10.75 9.485.045 4.878 3.343 8.04 8.4 9.485l3.388.994c3.071.858 4.291 2.168 4.291 3.884 0 2.033-1.942 3.252-4.562 3.252-2.846 0-5.33-1.536-5.782-4.427Z"
              className="fill-black dark:fill-white"
            ></path>
            <path
              fill="#fa0050"
              d="m77.045 0-5.336 5.35h17.8v17.8l5.336-5.35V0Z"
            ></path>
          </svg>
        </div>
        {showPrivacyButton && (
          <div className="mt-4 flex w-full justify-center">
            <a className="btn" href="/datenschutz">
              Zum Datenschutz
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default DataPrivacyHeadline;
