import HeroHeader from "./HeroHeader";
import Features from "./Features";
import { CTA_1, CTA_2 } from "../components/CallToAction";
import DataOverview from "./DataOverview";
import EasyImplementation from "./EasyImplementation";
import Layout from "../components/Layout";
import Customers from "../components/CustomersLogos";
import DataPrivacyHeadline from "../components/DataPrivacyHeadline";

const StartPage = () => {
  return (
    <Layout>
      <HeroHeader />
      <Customers />
      <Features />
      <CTA_1 />
      <DataOverview />
      <DataPrivacyHeadline showPrivacyButton={true} />
      <EasyImplementation />
      <CTA_2 />
    </Layout>
  );
};

export default StartPage;
