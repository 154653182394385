import { apiHandlers } from "@/api";
import { SEGMENTS_LIST } from "@/constants";
import { Segment } from "@/types/segment";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export const useGetSegmentsListQuery = (): UseQueryResult<Segment[], Error> => {
  return useQuery<Segment[], Error>({
    queryKey: [SEGMENTS_LIST],
    queryFn: async () => {
      const response = await apiHandlers.segment.getSegments();
      return response.data;
    },
  });
};
